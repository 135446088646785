import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from "@mui/lab";
import { Link, Box, Chip, CircularProgress, Grid, IconButton, Rating, Tooltip, Typography, Button, Backdrop } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useOutletContext, Await } from "react-router-dom";
import { IApiService, LogItem } from "../../services/ApiService";
import { LogementPageOutletContext } from "../LogementPage";
import { Download, HelpOutline } from "@mui/icons-material";
import labelHandiToit from "../../assets/label-handitoit.pdf";
import { computeHandiToitScore } from "../../utils/computeHandiToitScore";

interface HistoriqueTabContentProps {
    readonly apiService: IApiService;
}

export const HistoriqueTabContent = (props: HistoriqueTabContentProps) => {
    const { logEntriesPromise, logementAccessor } = useOutletContext() as LogementPageOutletContext;
    let creationDate = logementAccessor.creationDate?.get();
    creationDate = creationDate ? new Date(creationDate.getFullYear(), creationDate.getMonth(), creationDate.getDate()) : undefined;
    let lastModificationDate = logementAccessor.lastModificationDate?.get();
    lastModificationDate = lastModificationDate ? new Date(lastModificationDate.getFullYear(), lastModificationDate.getMonth(), lastModificationDate.getDate()) : undefined;
    if (lastModificationDate === creationDate) {
        lastModificationDate = undefined;
    }
    const handleDownload = () => {
        if (!logementAccessor.id) {
            throw new Error("Unexpected");
        }
        if (!logementAccessor.version) {
            throw new Error("Unexpected");
        }
        setNeedPdfUrl({
            id: logementAccessor.id.get(),
            version: logementAccessor.version.get()
        });
    }
    const [needPdfUrl, setNeedPdfUrl] = useState<{ readonly id: number, readonly version: number} | undefined>(undefined);
    const navigateToPdf = useCallback(async (signal: AbortSignal) => {
        if (!needPdfUrl) {
            return;
        }
        const pdfUrl = await props.apiService.getDiagAccessiblitePdfUrl(needPdfUrl.id, needPdfUrl.version, signal);
        setNeedPdfUrl(undefined);
        if (pdfUrl) {
            window.location.assign(pdfUrl);
        }
      }, [needPdfUrl]);
    useEffect(() => {
        if (needPdfUrl) {
            const abortController = new AbortController();
            navigateToPdf(abortController.signal);
            return () => {
                abortController.abort();
            }
        }
    }, [needPdfUrl]);
    const handiToitScore = useMemo(() => {
        return computeHandiToitScore(logementAccessor);
    }, [logementAccessor]);
    return <React.Suspense fallback={<CircularProgress />}>
        {logEntriesPromise &&
        <Await resolve={logEntriesPromise}>
            {(logEntries: ReadonlyArray<LogItem>) => {
                const migrationIndex = logEntries.length -1;
                let lastIndex = migrationIndex;
                let olderDate = logEntries[lastIndex].createdDate;
                olderDate = new Date(olderDate.getFullYear(), olderDate.getMonth(), olderDate.getDate());
                const entries = [...logEntries];
                if (lastModificationDate && lastModificationDate < olderDate) {
                    olderDate = lastModificationDate;
                    lastIndex++;
                    entries.push({
                        createdDate: lastModificationDate,
                        comment: "Dernière mise à jour avant migration",
                        createdBy: "Ancienne base de données",
                        createdByFirstName: undefined,
                        createdByLastName: undefined
                    })
                }
                if (creationDate && creationDate < olderDate) {
                    olderDate = creationDate;
                    lastIndex++;
                    entries.push({
                        createdDate: creationDate,
                        comment: "Création",
                        createdBy: "Ancienne base de données",
                        createdByFirstName: undefined,
                        createdByLastName: undefined
                    })
                }
                return (<>
                <Backdrop
                    sx={{
                        color: (theme) => theme.palette.background.default,
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={needPdfUrl !== undefined}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container spacing={1}>
                    <Grid item xs={12} textAlign={"center"}>
                        <Button
                            variant="contained"
                            size="large"
                            startIcon={<Download />}
                            onClick={handleDownload}
                        >
                            Télécharger au format Pdf
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{
                            alignItems: "center",
                            display: "flex"
                        }}>
                            <Typography variant="body2">Notation HandiToit</Typography>
                            <Tooltip title={<>
                                    <Typography>
                                        La notation HandiToit permet d'évaluer pour ce logement le respect des préconisations de confort d'usage proposé par HandiToit Provence dans son cahier des charges, pour des utilisateurs en fauteuil roulant.<br/>
                                        <Link color="primary.contrastText"
                                        // target="_blank"
                                        href={labelHandiToit}>Pour en savoir plus cliquer ici.</Link>
                                    </Typography>
                                </>}>
                                <IconButton color="info">
                                    <HelpOutline/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Rating readOnly value={handiToitScore} max={3} />{handiToitScore}/3 
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{pt: 1.2, pb: 0.7}} variant="body2">Statut</Typography>
                        <Box>
                            {logementAccessor.complet && <Chip label={logementAccessor.complet.get() ? "Complet" : "Incomplet"} color={logementAccessor.complet.get() ? "success" : "error"} />}
                        </Box>
                    </Grid>
                </Grid>
                <Timeline sx={{
                    padding: 0
                }}>
                    {entries.map((x, i) => {
                        const comment = (x.createdBy === "System" && i === migrationIndex)
                            ? "Migration depuis l'ancienne base de données"
                            : x.comment?.trim();
                        return (
                        <TimelineItem key={i}>
                            <TimelineOppositeContent color="text.secondary" sx={{
                                flexGrow: 0,
                                padding: 1,
                                paddingLeft: 0
                            }}>
                                <Typography>{x.createdDate.toLocaleDateString()}</Typography>
                                <Typography>{x.createdDate.toLocaleTimeString()}</Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                {(lastIndex > i) && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent sx={{
                                padding: 1,
                                paddingRight: 0
                            }}>
                                {(x.createdByFirstName || x.createdByLastName) && <Typography>{x.createdByFirstName} {x.createdByLastName}</Typography>}
                                <Typography>{x.createdBy}</Typography>
                                <Typography>{comment}</Typography>
                            </TimelineContent>
                        </TimelineItem>);})
                    }
                </Timeline></>);
            }}
        </Await>}
    </React.Suspense>;
}