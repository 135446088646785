import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ag2rLogo from '../assets/logo-ag2r.jpg';
import handitoitLogo from '../assets/logo-handitoit.png';

export const HomePage = () => {
    const navigate = useNavigate();
    const goToBailleurs = () => {
        navigate("/bailleurs");
    }
    const goToNouveauLogement = () => {
        navigate("/new");
    }
    return (
        <>
            <Box sx={{p:2, textAlign: "center"}}>
                <Box>
                    <img src={handitoitLogo} width="120px" alt="Handitoit"/>
                </Box>
                <Typography variant="body2" textAlign="center" sx={{margin: 2}}>
                    en partenariat avec
                </Typography>
                <Box>
                    <img src={ag2rLogo} width="220px" alt="AG2R La Mondiale"/>
                </Box>
            </Box>
            <Container sx={{p: "1 0", position :"fixed", bottom: 0, top: "auto"}} maxWidth="md">
                <Stack direction={"row"} spacing={1} padding={1}>
                    <Button
                        onClick={() => goToBailleurs()}
                        fullWidth
                        variant="contained">
                        Liste des logements
                    </Button>
                    <Button
                        onClick={() => goToNouveauLogement()}
                        fullWidth
                        variant="contained">
                        Nouveau logement
                    </Button>
                </Stack>
            </Container>
        </>
    );
}