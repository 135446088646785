import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import { CssBaseline } from '@mui/material'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ConfirmProvider } from 'material-ui-confirm';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    <React.StrictMode>
      <CssBaseline />
      <ConfirmProvider>
        <App />
      </ConfirmProvider>
    </React.StrictMode>
  </>
);
