import { Logement } from "../dataModel/generated/Logement";
import { LogementAccessorBase } from "../dataModel/generated/LogementAccessorBase";
import { normalizeLogement } from "../dataModel/generated/normalizeLogement";

export class LogementAccessor extends LogementAccessorBase {
    constructor(logement: Logement | undefined, setter: (logement: Logement) => void) {
        super(logement, (logement: Logement) => {
            setter(normalizeLogement(logement));
        });
    }
}