import { Add } from "@mui/icons-material";
import React, { useContext } from "react";
import { useLoaderData, Await, useNavigate } from "react-router-dom";
import { NavigationListSkeleton, NavigationList } from "../components/NavigationList";
import { StyledFloatingActionButton } from "../components/StyledFloatingActionButton";
import { searchContext } from "../layout/MainLayout";

export const ProgrammesPage = () => {
    const data = useLoaderData() as undefined | {
        bailleurId: number,
        programmes: Promise<ReadonlyArray<string>>
    };
    const navigate = useNavigate();
    const handleAddClick = () => {
        if (!data) {
            throw new Error("Unexpected");
        }
        navigate(`../new?bailleur=${data.bailleurId}`);
    };
    const context = useContext(searchContext);
    const search = context?.search.trim().toLocaleLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "");
    return (
        <React.Suspense fallback={<NavigationListSkeleton/>}>
             {data && <Await resolve={data.programmes}>
                {(programmes: ReadonlyArray<string>) => {
                    programmes = search ? programmes.filter(x => x.toLocaleLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").indexOf(search) >= 0) : programmes;
                    return <>
                        <NavigationList items={programmes.map(x => {
                            return {
                                text: x || "- Hors programme -",
                                path: `../${data.bailleurId}/logements/${encodeURIComponent(x)}`,
                            };
                        })} />
                        <StyledFloatingActionButton color="primary" aria-label="ajouter" onClick={handleAddClick}>
                            <Add />
                        </StyledFloatingActionButton>
                    </>;
                }}
            </Await>}
        </React.Suspense>
    );
};