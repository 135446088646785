/* Generated file */

export enum Financement {
	NonPrecise = "NonPrecise",
	PALULOS = "PALULOS",
	PLAI = "PLAI",
	PLI_LIBRE = "PLI_LIBRE",
	PLS = "PLS",
	PLUS = "PLUS",
}
