import { Home, Troubleshoot, Attachment, Update, Notes } from "@mui/icons-material";
import { Tabs } from "@mui/material";
import { matchPath } from "react-router-dom";
import { SubHeaderProps } from "../../layout/MainLayout";
import { LinkTab } from "../../components/LinkTab";

export const ViewTabs = (props: SubHeaderProps)  => {
    const routeMatch = matchPath(`/logement/:id/view/:tab?`, props.path)
    if (!routeMatch) {
        throw new Error("Unexpected");
    }
    const currentTab = routeMatch.params.tab ?? "";
    const rootPath = `/logement/${routeMatch.params.id}/view/`;
    return (
        <Tabs value={currentTab} variant="fullWidth" aria-label="Onglets">
            <LinkTab
                value=""
                rootPath={rootPath}
                icon={<Home />}
                aria-label="Logement" />
            <LinkTab
                value="diagnostic"
                rootPath={rootPath}
                icon={<Troubleshoot />}
                aria-label="Diagnostic" />
            <LinkTab
                value="comments"
                rootPath={rootPath}
                icon={<Notes />}
                aria-label="Commentaires" />
            <LinkTab
                value="documents"
                rootPath={rootPath}
                icon={<Attachment />}
                aria-label="Documents" />
            <LinkTab
                value="historique"
                rootPath={rootPath}
                icon={<Update />}
                aria-label="Historique" />
        </Tabs>);
}