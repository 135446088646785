import { Stack } from "@mui/material";
import { LogementPageOutletContext, ViewKind } from "../LogementPage";
// import { OutputFile } from "../../components/OutputFile";
import { useOutletContext } from "react-router";
// import { OutputMedias } from "../../components/OutputMedias";
import { IApiService } from "../../services/ApiService";
import { OutputMedias } from "../../components/OutputMedias";

export interface DocumentsTabContentProps {
    readonly viewKind: ViewKind;
    readonly apiService: IApiService
}

export const DocumentsTabContent = (props: DocumentsTabContentProps) => {
  const { logementAccessor } = useOutletContext() as LogementPageOutletContext;
  const programme = logementAccessor.nomProgramme;
  const numLogement = logementAccessor.numLogement;
  // const ficheAccessor = logementAccessor.fiche;
  const mediasAccessor = logementAccessor.medias;
  return (<Stack spacing={1}>
    {/* <OutputFile
      readonly={props.viewKind === ViewKind.Read}
      title="Fiche de relevé"
      fileName={`Fiche de relevé ${programme?.get()} ${numLogement?.get()}`}
      valueAccessor={ficheAccessor}
      apiService={props.apiService}
    /> */}
    <OutputMedias
      readonly={props.viewKind === ViewKind.Read}
      title="Photos, plans, etc (ATTENTION : ces documents pourront être accessibles au public)"
      fileName={`logement ${programme?.get()} ${numLogement?.get()}`}
      valueAccessor={mediasAccessor}
      apiService={props.apiService}
    />
  </Stack>);
};