import { apiClient, apiDelete, apiGet, apiPost } from "./apiClient";
import { Antiforgery } from "./Antiforgery";
import axios from "axios";
import { User } from "./User";
import { WrappedResult } from "./WrappedResult";
import { Logement } from "../dataModel/generated/Logement";

export interface ContextInfo {
    antiforgery: Antiforgery;
    user: User | undefined;
    isProduction: boolean;
}

export interface BailleurItem {
    readonly id: number;
    readonly nom: string;
}

export interface LogItem {
    readonly comment: string | undefined;
    readonly createdDate: Date;
    readonly createdBy: string | undefined;
    readonly createdByFirstName: string | undefined;
    readonly createdByLastName: string | undefined;
}

export interface UserDetail {
    readonly firstName: string | undefined,
    readonly lastName: string | undefined,
}

export interface IApiService {
    readonly antiforgery: Antiforgery | undefined;
    readonly user: User | undefined;
    readonly isProduction: boolean;
    login(email: string, password: string, returnUrl: string): Promise<undefined | Readonly<Record<string, ReadonlyArray<string>>>>;
    sendResetPasswordEmail(email: string): Promise<undefined | Readonly<Record<string, ReadonlyArray<string>>>>;
    resetPassword(email: string, password: string, confirmPassword: string, code: string): Promise<undefined | Readonly<Record<string, ReadonlyArray<string>>>>;
    getBailleurs(abortSignal: AbortSignal): Promise<ReadonlyArray<BailleurItem>>;
    getBailleur(bailleurId: number, abortSignal: AbortSignal): Promise<BailleurItem>;
    getProgrammes(bailleurId: number, abortSignal: AbortSignal): Promise<ReadonlyArray<string>>;
    getLogements(bailleurId: number, programme: string | undefined, abortSignal: AbortSignal): Promise<ReadonlyArray<Logement>>;
    getLogement(logementId: number, abortSignal: AbortSignal): Promise<Logement>;
    deleteLogement(logementId: number, abortSignal: AbortSignal): Promise<void>;
    upsertLogement(logement: Logement, abortSignal: AbortSignal): Promise<Logement | undefined>;
    getLogEntries(logementId: number, abortSignal: AbortSignal): Promise<ReadonlyArray<LogItem>>;
    getCommunes(abortSignal: AbortSignal): Promise<ReadonlyArray<CommuneItem>>;
    getDiagAccessiblitePdfUrl(logementId: number, version: number, abortSignal: AbortSignal): Promise<string | undefined>;
    getUserDetail(): UserDetail | undefined;
    setUserDetail(userDetail: UserDetail, abortSignel: AbortSignal): Promise<void>;
}

export interface CommuneItem {
    readonly codeINSEE: string;
    readonly codePostal: string;
    readonly nom: string;
}

export class ApiService implements IApiService {
    public readonly antiforgery: Antiforgery | undefined;
    public readonly user: User | undefined;
    public readonly isProduction: boolean;
    private userDetail: UserDetail | undefined;
    private readonly reload: (returnUrl: string) => void;
    constructor(
        contextInfo: ContextInfo | undefined,
        userDetail: UserDetail | undefined,
        reload: (returnUrl: string) => void
    ) {
        this.antiforgery = contextInfo?.antiforgery;
        this.user = contextInfo?.user;
        this.isProduction = contextInfo ? contextInfo.isProduction : true;
        this.userDetail = userDetail;
        this.reload = reload;
    }
    public async login(email: string, password: string, returnUrl: string): Promise<undefined | Readonly<Record<string, ReadonlyArray<string>>>> {
        if (!this.antiforgery) {
            throw new Error("Unexpected");
        }
        let errors: undefined | Readonly<Record<string, ReadonlyArray<string>>> = undefined;
        await apiClient.post("Account/Login", {
            email: email,
            password: password
        }, {
            headers: {
                [this.antiforgery.headerName]: this.antiforgery.requestToken
            }
        })
        .then(() => {
            this.reload(returnUrl);
        })
        .catch(error => {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    errors = error.response?.data.errors || error.response?.data;
                }
            }
            if (!errors) {
                throw error;
            }
        });
        return errors;
    }
    public async resetPassword(email: string, password: string, confirmPassword: string, code: string): Promise<undefined | Readonly<Record<string, ReadonlyArray<string>>>> {
        if (!this.antiforgery) {
            throw new Error("Unexpected");
        }
        let errors: undefined | Readonly<Record<string, ReadonlyArray<string>>> = undefined;
        await apiClient.post("Account/ResetPassword", {
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            code: code
        }, {
            headers: {
                [this.antiforgery.headerName]: this.antiforgery.requestToken
            }
        })
        .catch(error => {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    errors = error.response?.data.errors || error.response?.data
                }
            }
            if (!errors && !axios.isCancel(error)) {
                throw error;
            }
        });
        return errors;
    }
    public async sendResetPasswordEmail(email: string): Promise<undefined | Readonly<Record<string, ReadonlyArray<string>>>> {
        if (!this.antiforgery) {
            throw new Error("Unexpected");
        }
        let errors: undefined | Readonly<Record<string, ReadonlyArray<string>>> = undefined;
        await apiClient.post("Account/ForgotPassword", {
            email: email
        }, {
            headers: {
                [this.antiforgery.headerName]: this.antiforgery.requestToken
            }
        })
        .catch(error => {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    errors = error.response?.data.errors || error.response?.data
                }
            }
            if (!errors && !axios.isCancel(error)) {
                throw error;
            }
        });
        return errors;
    }
    public getUserDetail(): UserDetail | undefined {
        return this.userDetail;
    }
    public async setUserDetail(userDetail: UserDetail, abortSignal: AbortSignal): Promise<void> {
        if (!this.antiforgery) {
            throw new Error("Unexpected");
        }
        await apiPost("Account/UserDetail", userDetail, {
            signal: abortSignal,
            headers: {
                [this.antiforgery.headerName]: this.antiforgery.requestToken
            }
        });
        this.userDetail = userDetail;
    }
    public async getBailleurs(abortSignal: AbortSignal): Promise<ReadonlyArray<BailleurItem>> {
        if (!this.user) {
            throw new Error("Unexpected");
        }
        const data = await apiGet<WrappedResult<BailleurItem>>("Bailleurs", {
            signal: abortSignal
        });
        const items = data?.items;
        return items ?? [];
    }
    public async getBailleur(bailleurId: number, abortSignal: AbortSignal): Promise<BailleurItem> {
        const bailleurs = await this.getBailleurs(abortSignal);
        const bailleurItems = bailleurs.filter(x => x.id === bailleurId);
        if (bailleurItems.length !== 1) {
            throw new Error("Not found");
        }
        return bailleurItems[0];
    }
    public async getProgrammes(bailleurId: number, abortSignal: AbortSignal): Promise<ReadonlyArray<string>> {
        if (!this.user) {
            throw new Error("Unexpected");
        }
        const data = await apiGet<WrappedResult<string>>(`Programmes/${bailleurId}`, {
            signal: abortSignal
        });
        const items =  data?.items;
        return items ?? [];
    }
    public async getLogements(bailleurId: number, programme: string | undefined, abortSignal: AbortSignal): Promise<ReadonlyArray<Logement>> {
        if (!this.user) {
            throw new Error("Unexpected");
        }
        const p = programme ? "?" + new URLSearchParams({
            programme: programme
        }).toString() : "";
        const data = await apiGet<WrappedResult<LogementRaw>>(`Logements/${bailleurId}${p}`, {
            signal: abortSignal
        });
        const items =  data?.items;
        return (items ?? []).map(x => {
            return {
                ...x,
                creationDate: new Date(Date.parse(x.creationDate)),
                lastModificationDate: new Date(Date.parse(x.lastModificationDate))
            }
        });
    }
    public async getDiagAccessiblitePdfUrl(logementId: number, version: number, abortSignal: AbortSignal): Promise<string | undefined> {
        if (!this.user) {
            throw new Error("Unexpected");
        }
        const data = await apiGet<{ readonly url: string }>(`Logement/${logementId}/pdfUrl/${version}`, {
            signal: abortSignal
        });
        return data?.url;
    }
    public async deleteLogement(logementId: number, abortSignal: AbortSignal): Promise<void> {
        if (!this.antiforgery) {
            throw new Error("Unexpected");
        }
        await apiDelete(`Logement/${logementId}`, {
            signal: abortSignal,
            headers: {
                [this.antiforgery.headerName]: this.antiforgery.requestToken
            }
        });
    }
    public async getLogement(logementId: number, abortSignal: AbortSignal): Promise<Logement> {
        if (!this.user) {
            throw new Error("Unexpected");
        }
        const data = await apiGet<LogementRaw>(`Logement/${logementId}`, {
            signal: abortSignal
        });
        if (!data) {
            throw new Error("Not found");
        }
        return {
            ...data,
            creationDate: new Date(Date.parse(data.creationDate)),
            lastModificationDate: new Date(Date.parse(data.lastModificationDate)),
            comment: "Mise à jour"
        };
    }
    public async upsertLogement(logement: Logement, abortSignal: AbortSignal): Promise<Logement | undefined> {
        if (!this.user) {
            throw new Error("Unexpected");
        }
        if (!this.antiforgery) {
            throw new Error("Unexpected");
        }
        const data = await apiPost<LogementRaw, Logement>(`Logement/${logement.id}`, logement, {
            signal: abortSignal,
            headers: {
                [this.antiforgery.headerName]: this.antiforgery.requestToken
            }
        });
        if (!data) {
            return undefined;
        }
        return {
            ...data,
            creationDate: new Date(Date.parse(data.creationDate)),
            lastModificationDate: new Date(Date.parse(data.lastModificationDate))
        };
    }
    async getLogEntries(logementId: number, abortSignal: AbortSignal): Promise<ReadonlyArray<LogItem>> {
        if (!this.user) {
            throw new Error("Unexpected");
        }
        const data = await apiGet<WrappedResult<LogItemRaw>>(`Logement/${logementId}/Logs`, {
            signal: abortSignal
        });
        if (!data) {
            throw new Error("Not found");
        }
        const items =  data?.items;
        return (items ?? []).map(x => {
            return {
                ...x,
                createdDate: new Date(Date.parse(x.createdDate+"Z"))
            }
        });
    }
    async getCommunes(abortSignal: AbortSignal): Promise<ReadonlyArray<CommuneItem>> {
        if (!this.user) {
            throw new Error("Unexpected");
        }
        const data = await apiGet<WrappedResult<CommuneItem>>("Utils/CommunesPACA", {
            signal: abortSignal
        });
        const items =  data?.items;
        return items ?? [];
    }
}

type LogementRaw = Omit<Logement, "creationDate" & "lastModificationDate"> & { creationDate: string, lastModificationDate: string };
type LogItemRaw = Omit<LogItem, "createdDate"> & { createdDate: string };