import { BottomNavigation, BottomNavigationAction, Box, Card, CardActionArea, CardContent, CardHeader, CircularProgress, Divider, Grid, Paper, Stack, TextareaAutosize, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { LogementPageOutletContext, ViewKind } from "../LogementPage";
import { AddAPhoto, ArrowBackIos, ArrowForwardIos, CancelRounded, CheckCircle, NotInterested } from "@mui/icons-material";
import { useOutletContext } from "react-router-dom";
import { IValueAccessor } from "../../utils/ValueAccessor";
import { DiagnosticReponse } from "../../dataModel/generated/DiagnosticReponse";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { diagnosticQuestions, diagnosticSections } from "../generated/DiagnosticQuestions";
import { DiagQuestionDescription } from "../../utils/DiagQuestionDescription";
import { LogementAccessor } from "../../services/LogementAccessor";
import { VisuallyHiddenInput } from "../../components/VisuallyHiddenInput";
import { apiClient } from "../../services/apiClient";
import Compressor from 'compressorjs';
import { Upload } from "upload";
import { IApiService } from "../../services/ApiService";

export interface DiagnosticTabContentProps {
    readonly viewKind: ViewKind;    
    readonly apiService: IApiService;
}

const icons = (value: DiagnosticReponse | undefined) => {
    switch (value) {
        case DiagnosticReponse.Non:
            return <CancelRounded aria-label="Non" color="error"/>;
        case DiagnosticReponse.Oui:
            return <CheckCircle aria-label="Oui" color="success"/>;
        case DiagnosticReponse.SansObjet:
            return <NotInterested aria-label="Sans objet" color="disabled"/>;
        case undefined:
        case null:
            return <></>;
        default:
            throw new Error("Not supported");
    }
};

export const DiagnosticTabContent = (props: DiagnosticTabContentProps) => {
    const { logementAccessor } = useOutletContext() as LogementPageOutletContext;
    const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);
    const validQuestions = useMemo(() => {
        return allQuestions.reduce<ReadonlyArray<DiagQuestionDescription & { readonly id: string }>>((p, c) => {
            const q = diagnosticQuestions[c];
            if (q.condition && !q.condition(logementAccessor)) {
                return p;
            }
            return [...p, { id: c, ...q }];
        }, [])
    }, [allQuestions, diagnosticQuestions, logementAccessor]);
    const valideQuestionIds = useMemo(() => {
        return validQuestions.reduce<Readonly<Record<string, number>>>((p, c, i) => {
            return {...p, [c.id]: i };
        }, {});
    }, [validQuestions]);
    if (typeof currentIndex === "number") {
        return <DiagnosticQuestionTabContent
            validQuestions={validQuestions}
            logementAccessor={logementAccessor}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            apiService={props.apiService}
        />;
    }
    return (<Stack spacing={1}>
        {diagnosticSections.map((section, sectionIndex) => {
            return (
        <Card key={`section${sectionIndex}`} variant="outlined" sx={{borderWidth: 0}}>
            <CardHeader title={section.label} titleTypographyProps={{
                component: "h2",
                variant: "subtitle1",
                color: "primary"
            }} sx={{
                p: 0.4
            }} />
            {section.children.map((item, index) =>
                <div key={`section${sectionIndex}_item${index}`}><Item 
                    item={item}
                    logementAccessor={logementAccessor}
                    validQuestions={validQuestions}
                    valideQuestionIds={valideQuestionIds}
                    viewKind={props.viewKind}
                    setCurrentIndex={setCurrentIndex}/></div>
            )}
        </Card>);
        })}
    </Stack>);
}

interface ItemProps {
    readonly item: unknown;
    readonly logementAccessor: LogementAccessor;
    readonly validQuestions: readonly (DiagQuestionDescription & { readonly id: string })[];
    readonly valideQuestionIds: Readonly<Record<string, number>>;
    readonly setCurrentIndex: React.Dispatch<React.SetStateAction<number | undefined>>
    readonly viewKind: ViewKind;
}

const Item = (props: ItemProps) => {
    const { item, logementAccessor, validQuestions, valideQuestionIds, viewKind, setCurrentIndex } = props;
    switch (typeof item) {
        case "object": {
            const condition = (item as {condition: any})["condition"];
            if (condition && !condition(logementAccessor)) {
                return <React.Fragment></React.Fragment>;
            }
            return <React.Fragment>
                <Divider/>
                <CardContent sx={{
                    p: 0.4,
                    '&:last-child': {
                        p: 0.4,
                    },
                }}>
                    <Typography component="h3" variant="subtitle2" sx={{mt: 1}}>
                        {(item as {title: string}).title}
                    </Typography>
                </CardContent>
            </React.Fragment>
        }
        case "string": {
            const questionIndex = valideQuestionIds[item];
            if (typeof questionIndex !== "number") {
                return <React.Fragment></React.Fragment>;
            }
            const q = validQuestions[questionIndex];
            const accessor = (logementAccessor as unknown as Record<string, any>)[item];
            return <>
                <Divider/>
                <CardContent sx={{
                    p: 0.4,
                    '&:last-child': {
                        p: 0.4,
                    },
                }}>
                    {q.isTextarea && <CommentCardContent
                        questionIndex={questionIndex}
                        setCurrentIndex={setCurrentIndex}
                        viewKind={viewKind}
                        title={q.label}
                        accessor={accessor}
                    />}
                    {!q.isTextarea && <QuestionCardContent
                        questionIndex={questionIndex}
                        setCurrentIndex={setCurrentIndex}
                        viewKind={viewKind}
                        title={q.label}
                        accessor={accessor}
                    />}
                </CardContent>
            </>
        }
        default:
            throw new Error("Not supported");
    }
};

interface CommentCardContentProps {
    readonly questionIndex: number;
    readonly setCurrentIndex: (newValue: number | undefined) => void;
    readonly viewKind: ViewKind
    readonly title: string | undefined;
    readonly accessor: IValueAccessor<string | undefined> | undefined;
}

const CommentCardContent = (props: CommentCardContentProps) => {
    const { questionIndex, setCurrentIndex, viewKind, title, accessor } = props;
    const handleClick = () => {
        setCurrentIndex(questionIndex);
    }
    if (viewKind !== ViewKind.Read) {
        return <CardActionArea onClick={handleClick}>
            <CommentCardContent
                questionIndex={questionIndex}
                setCurrentIndex={setCurrentIndex}
                viewKind={ViewKind.Read}
                title={title}
                accessor={accessor} />
        </CardActionArea>;
    }
    return <>
        <Typography variant="body2">
            {title}
        </Typography>
        <Box>
            <TextareaAutosize
                disabled
                minRows={7}
                value={accessor?.get() ?? ""}
                onChange={(ev) => accessor?.set(ev.target.value)}
                style={{width: "100%", resize: "none"}}
            />
        </Box>
    </>
}

interface QuestionCardContentProps {
    readonly questionIndex: number;
    readonly setCurrentIndex: (newValue: number | undefined) => void;
    readonly viewKind: ViewKind
    readonly title: string | undefined;
    readonly accessor: IValueAccessor<DiagnosticReponse | undefined> | undefined;
}

const QuestionCardContent = (props: QuestionCardContentProps) => {
    const { questionIndex, setCurrentIndex, viewKind, title, accessor } = props;
    const handleClick = () => {
        setCurrentIndex(questionIndex);
    }
    if (viewKind !== ViewKind.Read) {
        return <CardActionArea onClick={handleClick}>
            <QuestionCardContent
                questionIndex={questionIndex}
                setCurrentIndex={setCurrentIndex}
                viewKind={ViewKind.Read}
                title={title}
                accessor={accessor} />
        </CardActionArea>;
    }
    return (
    <Grid container spacing={1} alignItems="center">
        <Grid item xs={11}>
            <Typography variant="body2">
                {title?.split("\n").map(x => <>{x}<br /></>)}
            </Typography>
        </Grid>
        <Grid item xs={1} sx={{textAlign: "right"}}>
            <Typography variant="body2" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "flex-end"
                }}>
                {icons(accessor?.get())}
            </Typography>
        </Grid>
    </Grid>);
}

const allQuestions = diagnosticSections.reduce<string[]>((all, s) => {
    const children = s.children.reduce<string[]>((p, c) => {
        if (typeof c !== "string") {
            return p;
        }
        const question = (diagnosticQuestions as Record<string, object>)[c];
        if (!question) {
            throw new Error("Unexpected");
        }
        return [...p, c];
    }, [])
    return all.concat(children);
}, []);

const sectionIndexByQuestionId = diagnosticSections.reduce<Record<string, number>>((all, s, sectionIndex) => {
    const children = s.children.reduce<Record<string, number>>((p, c) => {
        if (typeof c !== "string") {
            return p;
        }
        const question = (diagnosticQuestions as Record<string, object>)[c];
        if (!question) {
            throw new Error("Unexpected");
        }
        return {...p, [c]: sectionIndex};
    }, {})
    return {...all, ...children};
}, {});

interface DiagnosticQuestionTabContentProps {
    readonly validQuestions: ReadonlyArray<DiagQuestionDescription  & { readonly id: string }>;
    readonly logementAccessor: LogementAccessor;
    readonly currentIndex: number;
    readonly setCurrentIndex: (newValue: number | undefined) => void;
    readonly apiService: IApiService;
}

const DiagnosticQuestionTabContent = (props: DiagnosticQuestionTabContentProps) => {
    const { validQuestions, logementAccessor, currentIndex, setCurrentIndex, apiService } = props;
    useEffect(() => {
        if (currentIndex !== undefined && currentIndex < 0 || currentIndex >= validQuestions.length) {
            setCurrentIndex(undefined);
        }
    }, [currentIndex]);
    if (currentIndex < 0 || currentIndex >= validQuestions.length) {
        return <></>;
    }

    const itemRender = (sectionLabel: string, item: DiagQuestionDescription & { readonly id: string }) => {
        if (item.isTextarea) {
            const accessor = (logementAccessor as unknown as Record<string, IValueAccessor<string | undefined> | undefined>)[item.id];
            return <CommentRender
                    accessor={accessor}
                    sectionLabel={sectionLabel}
                    label={item.label}/>;
        } else {
            return <QuestionRender
                    sectionLabel={sectionLabel}
                    label={item.label}/>;
        }
    };

    const responseRender = (item: DiagQuestionDescription & { readonly id: string }) => {
        if (item.isTextarea) {
            return <></>;
        }
        const accessor = (logementAccessor as unknown as Record<string, IValueAccessor<DiagnosticReponse | undefined> | undefined>)[item.id];
        return <ResponseButtonGroup
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                accessor={accessor}/>;
    }

    const item = validQuestions[currentIndex];
    const sectionIndex = sectionIndexByQuestionId[item.id];
    const section = diagnosticSections[sectionIndex];

    return <>
        <div>
            {itemRender(section.label, item)}
        </div>       
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <div>
                    {responseRender(item)}
                </div>
            <DiagnosticNavigationBar
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                mediasValueAccessor={logementAccessor.medias}
                apiService={apiService}/>
        </Paper>
    </>;
}

interface CommentRenderProps {
    readonly accessor: IValueAccessor<string | undefined> | undefined;
    readonly sectionLabel: string;
    readonly label: string | undefined;
}

const CommentRender = (props: CommentRenderProps) => {
    const { accessor, sectionLabel, label } = props;
    return <Stack spacing={5}>
        <Typography component="h2"
            variant="subtitle1"
            color="primary">
            {sectionLabel}
        </Typography>
        <Typography variant="subtitle2">
            {label?.split("\n").map(x => <>{x}<br /></>)}
        </Typography>
        <Box>
            <TextareaAutosize
                minRows={7}
                value={accessor?.get() ?? ""}
                onChange={(ev) => accessor?.set(ev.target.value)}
                style={{width: "100%", resize: "none"}}
            />
        </Box>
    </Stack>;
};

interface QuestionRenderProps {
    readonly sectionLabel: string;
    readonly label: string | undefined;
}

const QuestionRender = (props: QuestionRenderProps) => {
    const { /*accessor,*/ sectionLabel, label } = props;
    return <Stack spacing={5}>
        <Typography component="h2"
            variant="subtitle1"
            color="primary">
            {sectionLabel}
        </Typography>
        <Typography variant="subtitle2" fontSize={22}>
            {label?.split("\n").map(x => <>{x}<br /></>)}
        </Typography>
    </Stack>;
};

interface ResponseButtonGroupProps {
    readonly accessor: IValueAccessor<DiagnosticReponse | undefined> | undefined;
    readonly currentIndex: number;
    readonly setCurrentIndex: (index: number) => void;
}

const ResponseButtonGroup = (props: ResponseButtonGroupProps) => {
    const { accessor, currentIndex, setCurrentIndex } = props;
    return <ToggleButtonGroup
        value={accessor?.get()}
        exclusive
        size="large"
        onChange={(_, value) => {
            accessor?.set(value);
            setCurrentIndex(currentIndex + 1);
        }}
        aria-label="réponse"
        fullWidth
        sx={{
            justifyContent: "center",
        }}
    >
        <ToggleButton value={DiagnosticReponse.Oui} aria-label="oui">
            <Stack alignItems="center" spacing={1}>
                {icons(DiagnosticReponse.Oui)}
                <Typography sx={{
                    fontSize: "0.8em"
                }}>
                    Oui
                </Typography>
            </Stack>
        </ToggleButton>
        <ToggleButton value={DiagnosticReponse.Non} aria-label="non">
            <Stack alignItems="center" spacing={1}>
                {icons(DiagnosticReponse.Non)}
                <Typography sx={{
                    fontSize: "0.8em"
                }}>
                    Non
                </Typography>
            </Stack>
        </ToggleButton>
        <ToggleButton value={DiagnosticReponse.SansObjet} aria-label="sans objet">
            <Stack alignItems="center" spacing={1}>
                {icons(DiagnosticReponse.SansObjet)}
                <Typography sx={{
                    fontSize: "0.8em"
                }}>
                    Sans objet
                </Typography>
            </Stack>
        </ToggleButton>
    </ToggleButtonGroup>;
}; 

interface DiagnosticNavigationBarProps {
    readonly currentIndex: number;
    readonly setCurrentIndex: (index: number) => void;
    readonly mediasValueAccessor: IValueAccessor<ReadonlyArray<string>> | undefined;
    readonly apiService: IApiService
}

const DiagnosticNavigationBar = (props: DiagnosticNavigationBarProps) => {
    const { currentIndex, setCurrentIndex, mediasValueAccessor, apiService } = props;
    const baseApiUrl = apiClient.getUri();
    const [uploading, setUploading] = useState(false);
    const [_progress, setProgress] = useState(0);
    const handleFileAdd = async (file: File) => {
        setProgress(0);
        setUploading(true);
        let compressedFilePromise: Promise<FormData> | undefined = undefined;
        if (file.type.substring(0, 6) == "image/") {
            if (file.size > 1048576 * 2) { // 2 Mo
              compressedFilePromise = new Promise((resolve) => {
                new Compressor(file,  {
                  convertSize: 1048576 * 2,
                  success: (compressedFile) => {
                    const formData = new FormData();
                    formData.append('file', compressedFile, (compressedFile as File).name);
                    resolve(formData);
                  }
                });
              });
            }
        }
        if (!compressedFilePromise) {
          const formData = new FormData();
          formData.append('file', file, file.name);
          compressedFilePromise = Promise.resolve(formData);
        }
        const compressedFile = await compressedFilePromise;
        if (!apiService.antiforgery) {
          throw new Error("Unexpected");
        }
        // on doit pouvoir utiliser axios directement (ce composnt upload ne sert à rien)
        // exemple: https://github.com/fengyuanchen/compressorjs
        const upload = new Upload({
          url: `${baseApiUrl}/Files`,
          form: compressedFile,
          headers: {
            [apiService.antiforgery.headerName]: apiService.antiforgery.requestToken,
          },
        });
      
        upload.on('progress', progress => {
          setProgress(progress * 100);
        });
      
        const response = await upload.upload();
        if (response.status != 200) {
          alert("Une erreur s'est produite");
        } else {
          if (typeof response.data !== "string") {
            throw new Error("Unexpected");
          }
          const data = JSON.parse(response.data) as ReadonlyArray<{ id: string }>;
          if (data.length != 1) {
            throw new Error("Unexpected");
          }
          if (!mediasValueAccessor) {
            throw new Error("Unexpected");
          }
          mediasValueAccessor.set([...mediasValueAccessor.get(), data[0].id]);
        }
        setUploading(false);
        setProgress(0);
    };
    const handlePhoto = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files[0]) {
          handleFileAdd(files[0]);
          event.target.value = '';
        }
    };
    return <BottomNavigation>
        <BottomNavigationAction aria-label="précedent" onClick={() => setCurrentIndex(currentIndex - 1)} icon={<ArrowBackIos />} />
        {!uploading && <BottomNavigationAction disabled={!mediasValueAccessor} component="label" aria-label="photo" icon={<>
            <AddAPhoto />
            <VisuallyHiddenInput type="file" accept="image/*" capture onChange={handlePhoto} />
            </>} />}
        {uploading && <CircularProgress/>}
        <BottomNavigationAction aria-label="suivant" onClick={() => setCurrentIndex(currentIndex + 1)} icon={<ArrowForwardIos />} />
    </BottomNavigation>;
};