import { BailleurItem, CommuneItem } from "../services/ApiService";

export type Option<TValue> = {
    readonly value: TValue,
    readonly text: string
}

const UndefinedOptionValue = "NonPrecise";

export const UndefinedOption = {
    value: UndefinedOptionValue,
    text: "Non précisé"
};

export class LogementOptions {
    readonly communes: ReadonlyArray<CommuneItem> | undefined;
    readonly bailleur: ReadonlyArray<Option<number>>;
    readonly reservataire: ReadonlyArray<Option<string>>;
    readonly financement: ReadonlyArray<Option<string>>;
    readonly label: ReadonlyArray<Option<string>>;
    readonly caracteristique: ReadonlyArray<Option<string>>;
    readonly nature: ReadonlyArray<Option<string>>;
    readonly type: ReadonlyArray<Option<string>>;
    readonly niveau: ReadonlyArray<Option<number>>;
    readonly chauffage: ReadonlyArray<Option<string>>;
    readonly etatAvancementProjet: ReadonlyArray<Option<string>>;
    constructor(communes: ReadonlyArray<CommuneItem> | undefined, bailleurs: ReadonlyArray<BailleurItem> | undefined) {
        this.communes = communes;
        this.bailleur = bailleurs ? bailleurs.reduce<ReadonlyArray<Option<number>>>((p, c) => {
            return [
                ...p, {
                    value: c.id,
                    text: c.nom
                }
            ];
        }, []) : [];
        this.reservataire = [UndefinedOption, {
            value: "Prefecture",
            text: "Préfecture"
        }, {
            value: "ConseilDepartemental",
            text: "Conseil Départemental"
        }, {
            value: "Bailleur",
            text: "Bailleur"
        }, {
            value: "Mairie",
            text: "Mairie"
        }, {
            value: "ActionLogement",
            text: "Action Logement"
        }, {
            value: "EPCI",
            text: "EPCI"
        }];
        this.financement = [UndefinedOption, {
            value: "PLUS",
            text: "PLUS"
        }, {
            value: "PLAI",
            text: "PLAI"
        }, {
            value: "PLS",
            text: "PLS"
        }, {
            value: "PLI_LIBRE",
            text: "PLI/LIBRE"
        }, {
            value: "PALULOS",
            text: "PALULOS"
        }];
        this.label = [UndefinedOption, {
            value: "Aucun",
            text: "Aucun"
        }, {
            value: "LogServUFR",
            text: "Label logement service + UFR"
        }, {
            value: "LogServPMR",
            text: "Label logement service + PMR"
        }, {
            value: "Autre",
            text: "Autre"
        }];
        this.caracteristique = [UndefinedOption, {
            value: "Collectif",
            text: "Collectif"
        }, {
            value: "Individuel",
            text: "Individuel"
        }];
        this.nature = [UndefinedOption, {
            value: "Appartement",
            text: "Appartement"
        }, {
            value: "Maison",
            text: "Maison"
        }];
        this.type = [UndefinedOption, {
            value: "Studio",
            text: "Studio"
        }, {
            value: "T1",
            text: "T1"
        }, {
            value: "T2",
            text: "T2"
        }, {
            value: "T3",
            text: "T3"
        }, {
            value: "T4",
            text: "T4"
        }, {
            value: "T5",
            text: "T5"
        }, {
            value: "T6",
            text: "T6 (ou plus)"
        }];
        this.niveau = [...new Array(21)].reduce<ReadonlyArray<Option<number>>>((p, _c, i) => {
            return [
                ...p, {
                    value: i,
                    text: i === 0 ? "RDC" : "R+"+i
                }
            ];
        }, []);
        this.chauffage = [UndefinedOption, {
            value: "Collectif",
            text: "Collectif"
        }, {
            value: "IndGaz",
            text: "Individuel au gaz"
        }, {
            value: "IndFuel",
            text: "Individuel au fuel"
        }, {
            value: "IndElec",
            text: "Individuel électrique"
        }, {
            value: "Autre",
            text: "Autre"
        }];
        this.etatAvancementProjet = [UndefinedOption, {
            value: "Opportunite",
            text: "Opportunité"
        }, {
            value: "AnalyseTechnique",
            text: "Analyse Technique"
        }, {
            value: "Livre",
            text: "Livré"
        }];
    }
    public getCommunes(codePostal: string | undefined): ReadonlyArray<Option<string>> {
        if (!this.communes) {
            throw new Error("Unexpected");
        }
        const done: Record<string, boolean> = {};
        const items = this.communes.filter(x => codePostal ? x.codePostal === codePostal : false).sort((a, b) => {
            return a.nom < b.nom ? -1 : 1;
        }).reduce<ReadonlyArray<Option<string>>>((p, c) => {
            if (done[c.codeINSEE]) {
                return p;
            }
            done[c.codeINSEE] = true;
            return [
                ...p, {
                    value: c.codeINSEE,
                    text: c.nom
                }
            ];
        }, []);
        return items;
    }
}