import {useLocation, useNavigate } from "react-router-dom";
import { IApiService } from "../services/ApiService";
import { useState } from "react";
import { Alert, Button, Container, Stack, TextField } from "@mui/material";

export interface MePageProps {
    readonly apiService: IApiService;
}

export const MePage = (props: MePageProps) => {
    const { apiService } = props;
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const returnUrl = query.get("returnUrl");
    return <Container maxWidth="xs">
        <Stack 
            spacing={2}
            sx={{mt: 5}}>
            <MeForm returnUrl={returnUrl} apiService={apiService} />
        </Stack>
    </Container>;
}

interface MeFormProps {
    readonly apiService: IApiService;
    readonly returnUrl: string | null;
}

const MeForm = (props: MeFormProps) => {
    const { apiService, returnUrl } = props;
    const userDetail = apiService.getUserDetail();
    if (!userDetail) {
        throw new Error("Unexpected");
    }
    const [firstName, setFirstName] = useState(userDetail.firstName || "");
    const [lastName, setLastName] = useState(userDetail.lastName || "");
    const [inProgress, setInProgress] = useState<boolean>(false);
    const formEnabled = firstName.trim() && lastName.trim() && !inProgress;
    const navigate = useNavigate();
    const handleClick = async() => {
        if (!formEnabled) {
            return;
        }
        if (!firstName?.trim() || !lastName?.trim()) {
            throw new Error("Unexpected");
        }
        setInProgress(true);
        const abortController = new AbortController();
        await apiService.setUserDetail({
            firstName: firstName.trim(),
            lastName: lastName.trim()
        }, abortController.signal);
        setInProgress(false);
        navigate(returnUrl ?? "/", {
            replace: true
        });
    };
    return <>
        {returnUrl && <Alert severity="warning">
            L'usage de l'application nécessite que vous précisiez vos nom et prénom.
        </Alert>}
        <TextField
            inputProps={{ readOnly: true }}
            margin="normal"
            value={apiService.user?.userName}
            required
            fullWidth
            label="Adresse email"
        />
        <TextField
            margin="normal"
            value={firstName}
            onChange={(ev) => setFirstName(ev.target.value)}
            required
            fullWidth
            label="Prénom"
        />
        <TextField
            margin="normal"
            value={lastName}
            onChange={(ev) => setLastName(ev.target.value)}
            required
            fullWidth
            label="Nom"
        />
        <Button
            onClick={() => handleClick()}
            disabled={inProgress}
            type="submit"
            fullWidth
            variant="contained">
            Enregistrer
        </Button>
    </>;
}
