import { MenuItem, TextField } from "@mui/material";
import { UndefinedOption } from "../utils/LogementOptions";
import { IOptionValueAccessor } from "../utils/ValueAccessor";

export interface OutputSelectProps {
    readonly title: string;
    readonly required: boolean;
    readonly readonly: boolean;
    readonly valueAccessor: IOptionValueAccessor | undefined;
    readonly helperText?: string; 
}

const defaultOptions = [UndefinedOption];

export const OutputSelect = (props: OutputSelectProps) => {
    const { title, valueAccessor } = props;
    const options = valueAccessor?.options ?? defaultOptions;
    if (options.length < 1 || options[0] != UndefinedOption) {
        throw new Error("Unexpected");
    }
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        if (valueAccessor && !props.readonly) {
            valueAccessor.set(ev.target.value)
        }
    };
    return <TextField
        error={valueAccessor && props.required && valueAccessor.get() === UndefinedOption.value}
        value={valueAccessor?.get() ?? UndefinedOption.value}
        onChange={handleChange}
        select
        size="small"
        label={title}
        required={props.required}
        sx={{
            mt: (theme) => title ? 0 : theme.spacing(0.5)
        }}
        InputLabelProps={{
            shrink: true
        }}
        InputProps= {{
            readOnly: props.readonly,
        }}
        type="text"
        fullWidth
        helperText={props.helperText}
    >{options.map(option => (
        <MenuItem key={option.value} value={option.value}>
            {option.text}
        </MenuItem>
    ))}
    </TextField>
};