import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { IValueAccessor } from "../utils/ValueAccessor";

export interface OutputBooleanProps {
    readonly title: string;
    readonly readonly: boolean;
    readonly valueAccessor: IValueAccessor<boolean> | undefined;
}

export const OutputBoolean = (props: OutputBooleanProps) => {
    const { title, readonly, valueAccessor } = props;
    const checked = valueAccessor?.get() ?? false;
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        if (valueAccessor && !readonly) {
            valueAccessor.set(ev.target.checked)
        }
    };
    return <FormControlLabel
        control={<Checkbox checked={checked} disabled={readonly} onChange={handleChange} />}
        label={<Typography variant="body2">{title}</Typography>} />;
};