import { styled, Fab } from "@mui/material";

export const StyledFloatingActionButton = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    zIndex: 1,
    bottom: theme.spacing(3),
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: theme.spacing(3)
}));