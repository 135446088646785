import { TextField } from "@mui/material";
import { IValueAccessor } from "../utils/ValueAccessor";
import { useEffect, useState } from "react";

export interface OutputFloatProps {
    readonly title: string;
    readonly required: boolean;
    readonly readonly: boolean;
    readonly valueAccessor: IValueAccessor<number | undefined> | undefined;
}

export const OutputFloat = (props: OutputFloatProps) => {
    const { title, valueAccessor } = props;
    const getText = (): string => {
        const value = valueAccessor?.get() || undefined;
        if (!value) {
            return "";
        }
        return value.toString(10);
    }
    const [strValue, setStrValue] = useState("")
    useEffect(() => {
        setStrValue(getText())
    }, [valueAccessor]);
    const handleChange = (): void => {
        if (!valueAccessor || props.readonly) {
            return;
        }
        const value = strValue || undefined;
        if (!value) {
            valueAccessor.set(undefined);
            return;
        }
        const n = parseFloat(value);
        if (isNaN(n)) {
            valueAccessor.set(undefined);
            return;
        }

        valueAccessor.set(n);
    };
    return <TextField
        error={valueAccessor && props.required && (valueAccessor.get() === undefined || valueAccessor.get() === null)}
        size="small"
        value={strValue}
        onChange={ev => setStrValue(ev.target.value)}
        onBlur={handleChange}
        label={title}
        required={props.required}
        sx={{
            mt: (theme) => title ? 0 : theme.spacing(0.5)
        }}
        InputLabelProps={{
            shrink: true
        }}
        InputProps= {{
            readOnly: props.readonly
        }}
        type="text"
        fullWidth
    />
};