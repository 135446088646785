import { AppBar, Box, Breadcrumbs, Container, IconButton, Link, Menu, MenuItem, Skeleton, Stack, Toolbar, Typography } from "@mui/material";
import { Await, Outlet, UIMatch, useMatches, useNavigate, Link as RLink, useLocation, Navigate } from "react-router-dom";
import { Suspense, createContext, useMemo, useState } from "react";
import { AccountCircle } from "@mui/icons-material";
import { MainRouteData } from "../routeFactory";
import { IApiService } from "../services/ApiService";

export interface SubHeaderProps {
  readonly path: string;
}

export interface MainLayoutProps {
  readonly apiService: IApiService;
}

export interface SearchContext {
  readonly search: string;
  setSearch(value: string): void;
}

export const searchContext = createContext<SearchContext | undefined>(undefined);

export const MainLayout = (props: MainLayoutProps) => {
  const currentLocation = useLocation();
  const userDetail = props.apiService.getUserDetail();
  if (userDetail && currentLocation.pathname !== "/account/me") {
    if (!userDetail.firstName?.trim() || !userDetail.lastName?.trim()) {
      // firstName and lastName is required
      console.log("Redirect to /account/me");
      const returnUrl = currentLocation.pathname+currentLocation.search;
      const search = returnUrl ? `?returnUrl=${returnUrl}` : undefined;
      const to = {
          pathname: "/account/me",
          search: search,
      };
      return <Navigate to={to} replace/>
    }
  }
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const matches = useMatches() as UIMatch<MainRouteData, undefined | {
    subHeader?: (path: string) => React.FunctionComponent,
    crumb?: (path: string, data: MainRouteData) => ReadonlyArray<Promise<{path: string, title: string}>>
  }>[];
  const crumbs: ReadonlyArray<Promise<{path: string, title: string}>> = useMemo(() => matches
    .reduce<ReadonlyArray<Promise<{path: string, title: string}>>>((p, c) => {
      const crumb = c.handle?.crumb;
      if (!crumb) {
        return p;
      }
      return [...p, ...crumb(c.pathname, c.data)];
    }, []), [matches]);
  const subHeaders = useMemo(() => {
    return matches.reduce<ReadonlyArray<React.FunctionComponent>>((p, c) => {
      const subHeader = c.handle?.subHeader;
      if (!subHeader) {
        return p;
      }
      return [...p, subHeader(currentLocation.pathname)];
    }, []);
  }, [matches]);
  const header = <>
    <Toolbar>
        <Stack sx={{ flexGrow: 1 }}>
          <Breadcrumbs aria-label="Fil d'Ariane" sx={{ color: "primary.contrastText", fontSize: "0.8em" }}>
            {crumbs.slice(0, -1).map((x,i) => <CrumbLink key={i} link={x} />)}
          </Breadcrumbs>
          <Typography component="h1" variant="h6">
            {crumbs.slice(-1).map((x,i) => <CrumbLink key={i} link={x} />)}
          </Typography>
        </Stack>
        <IconButton
          size="large"
          aria-label="Utilisateur"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          sx={{ flexGrow: 0}}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled={currentLocation.pathname === "/account/me"} onClick={() => navigate("/account/me")}>Mon compte</MenuItem>
          <MenuItem onClick={() => navigate("/account/logout")}>Se déconnecter</MenuItem>
        </Menu>
    </Toolbar>
  </>;
  const [search, setSearch] = useState("");
  return (
    <searchContext.Provider value={{
      search: search,
      setSearch: setSearch
    }}>
      <AppBar component="header" position="relative" sx={{visibility: "hidden"}} aria-hidden="true">
          {header}
          <Box sx={{p: 0, m:0, bgcolor: 'background.default'}}>
            <Container sx={{p: "1 0"}} maxWidth="md">
              {subHeaders.map((x, i) => <div key={i}>{x({})}</div>)}
            </Container>
          </Box>
      </AppBar>
      <AppBar component="header" position="fixed" sx={{p:0}}>
          {header}
          <Box sx={{p: 0, m:0, bgcolor: 'background.default'}}>
            <Container sx={{p: "1 0"}} maxWidth="md">
              {subHeaders.map((x, i) => <div key={i}>{x({})}</div>)}
            </Container>
          </Box>
      </AppBar>
      <Container sx={{p: 0}} maxWidth="md">
        {userDetail && <Outlet />}
      </Container>
    </searchContext.Provider>
  );
};

interface CrumbLinkProps {
  link: Promise<{
      path: string,
      title: string
  }>
}

const CrumbLink = (props: CrumbLinkProps): React.ReactNode => {
  const location = useLocation();
  return (
      <Suspense fallback={<Skeleton variant="text" sx={{ width: "5em" }}/>}>
          <Await resolve={props.link}>
              {(link: {
                  path: string,
                  title: string
              }) => {
                if (decodeURI(location.pathname) !== link.path) {
                  return <Link
                    component={RLink}
                    to={link.path}
                    color="inherit"
                    underline="hover">
                    {link.title}
                  </Link>;
                } else {
                  return <Link
                  // component={RLink}
                  // to={link.path}
                  // href="#"
                  color="inherit"
                  underline="hover">
                  {link.title}
                </Link>;
                }}}
          </Await>
      </Suspense>);
}