import { useCallback, useEffect, useMemo, useState } from "react"
import { apiGet } from "./services/apiClient";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ApiService, ContextInfo, UserDetail } from "./services/ApiService";
import { routeFactory } from "./routeFactory";
import { useRegisterSW } from "virtual:pwa-register/react";
import { Box } from "@mui/material";

const reload = (returnUrl: string) => {
  window.location.replace(`${window.location.protocol}//${window.location.host}${returnUrl}`);
}

const checkSWUpdateDelay = 60 * 1000; // 1 minute

export const App = () => {
  const [contextInfo, setContextInfo] = useState<ContextInfo | undefined>();
  const [userDetail, setUserDetail] = useState<UserDetail | undefined>();
  const [ready, setReady] = useState(false);
  const initContextInfo = useCallback(async (signal: AbortSignal) => {
    if (!ready) {
      return;
    }
    const data = await apiGet<ContextInfo>('Context', {
      signal: signal
    });
    if (data) {
      setContextInfo(data);
    }
  }, [ready, setContextInfo]);
  const initUserDetail = useCallback(async (signal: AbortSignal) => {
    if (!ready) {
      return;
    }
    if (contextInfo?.user) {
      const data = await apiGet<UserDetail>("Account/UserDetail", {
        signal: signal
      });
      if (data) {
        setUserDetail(data);
      }
    }
  }, [ready, contextInfo, setUserDetail]);
  useEffect(() => {
    const abortController = new AbortController();
    initContextInfo(abortController.signal);
    return () => {
      abortController.abort();
    }
  }, [initContextInfo]);
  useEffect(() => {
    const abortController = new AbortController();
    initUserDetail(abortController.signal);
    return () => {
      abortController.abort();
    }
  }, [initUserDetail]);
  const apiService = useMemo(() => new ApiService(contextInfo, userDetail, reload), [contextInfo, userDetail]);
  const {
    //offlineReady: [offlineReady/*, setOfflineReady*/],
    needRefresh: [needRefresh/*, setNeedRefresh*/],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
        console.log(`Service Worker at: ${swUrl}`)
        r && setInterval(() => {
            console.log('Checking for sw update')
            r.update()
        }, checkSWUpdateDelay);
        setReady(true);
    },
    onRegisterError(error) {
      console.log('SW registration error', error)
    },
    // onOfflineReady() {
    //   console.log("OfflineReady");
    // }
  });
  //console.log("offlineReady", offlineReady);
  
  // TODO: utiliser un state supplémentaire (via un context) pour savoir si
  // on est dans un formulaire et dans ce cas ne pas faire de refresh
  // const isInForm = useIsInFormCOntext();
  const [isInForm, setIsInForm] = useState(false);
  useEffect(() => {
    if (needRefresh && !isInForm) {
        // on simule le click sur un bouton "reload" dans un dialogue
        // de confirmation
        setTimeout(() => {
            updateServiceWorker(true);
        });
    }
  }, [needRefresh, isInForm]);
  return <>
    {!apiService.isProduction && <Box color={theme => theme.palette.error.main} fontSize={8}
      sx={{
        position: "fixed",
        left: 10,
        bottom: 10,
        zIndex: 10000
      }}
    >Base de données de test</Box>}
    <RouterProvider router={createBrowserRouter(routeFactory(apiService, ready, setIsInForm))}/>
  </>;
}
