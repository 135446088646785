import { Alert, Button, Link, Stack, TextField } from "@mui/material"
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { IApiService } from "../services/ApiService";
import { PasswordInput } from "../components/PasswordInput";

export interface LoginPageProps {
    readonly api: IApiService;
}

export const LoginPage = (props: LoginPageProps) => {
    const location = useLocation();
    const [email, setEmail] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [errors, setErrors] = useState<Readonly<{[key: string]: ReadonlyArray<string>}> | undefined>(undefined)
    const formEnabled = email?.trim() && password?.trim() && !inProgress;
    const handleClick = async() => {
        if (!formEnabled) {
            return;
        }
        if (!email?.trim() || !password?.trim()) {
            throw new Error("Unexpected");
        }
        setErrors(undefined);
        setInProgress(true);
        const params = new URLSearchParams(location.search);
        const returnUrl = params.get("returnUrl") ?? "/";
        const errors = await props.api.login(email.trim(), password.trim(), returnUrl);
        setInProgress(false);
        if (errors) {
            setErrors(errors);
        } 
    };
    return (<>
        {errors && <Alert severity="error">
            {Object.entries(errors).map(x => x[1].map(error => <>
                {error}<br/>
            </>))}
        </Alert>}
        <Stack 
            spacing={2}
            sx={{mt:5}}>
            <TextField
                onChange={(ev) => setEmail(ev.currentTarget.value)}
                margin="normal"
                required
                fullWidth
                label="Adresse email"
                autoComplete="email"
                autoFocus
            />
            <PasswordInput
                label="Mot de passe"
                password={password}
                handlePassword={(ev) => setPassword(ev.currentTarget.value)}
                autoComplete="current-password"
            />
            <Button
                onClick={() => handleClick()}
                disabled={inProgress}
                type="submit"
                fullWidth
                variant="contained">
                Connexion
            </Button>
            <Link href="/account/forgot-password" variant="body2">
                Mot de passe perdu ou première connexion ?
            </Link>
        </Stack>
    </>);
}