import { useTheme, Typography, Paper, CircularProgress, LinearProgress, ImageList, ImageListItem, Link, useMediaQuery, IconButton, SpeedDial, SpeedDialIcon, SpeedDialAction, styled } from "@mui/material";
import { Box } from "@mui/system";
import { IValueAccessor } from "../utils/ValueAccessor";
import { ChangeEvent, useState } from "react";
import { IApiService } from "../services/ApiService";
import { apiClient } from "../services/apiClient";
import { Delete, FileOpen, PhotoCamera } from "@mui/icons-material";
import { VisuallyHiddenInput } from "./VisuallyHiddenInput";
import Compressor from 'compressorjs';
import { Upload } from "upload";

export interface OutputMediasProps {
    readonly title: string;
    readonly readonly: boolean;
    readonly fileName: string;
    readonly valueAccessor: IValueAccessor<ReadonlyArray<string>> | undefined;
    readonly apiService: IApiService
}
  
export const OutputMedias = (props: OutputMediasProps) => {
    const { valueAccessor, title, fileName, readonly, apiService } = props;
    const baseApiUrl = apiClient.getUri();
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('sm'));
    const columnCount = matchDownMd ? 1 : 2;
    const handleFileAdd = async (file: File) => {
        setProgress(0);
        setUploading(true);
        let compressedFilePromise: Promise<FormData> | undefined = undefined;
        if (file.type.substring(0, 6) == "image/") {
            if (file.size > 1048576 * 2) { // 2 Mo
              compressedFilePromise = new Promise((resolve) => {
                new Compressor(file,  {
                  convertSize: 1048576 * 2,
                  success: (compressedFile) => {
                    const formData = new FormData();
                    formData.append('file', compressedFile, (compressedFile as File).name);
                    resolve(formData);
                  }
                });
              });
            }
        }
        if (!compressedFilePromise) {
          const formData = new FormData();
          formData.append('file', file, file.name);
          compressedFilePromise = Promise.resolve(formData);
        }
        const compressedFile = await compressedFilePromise;
        if (!apiService.antiforgery) {
          throw new Error("Unexpected");
        }
        // on doit pouvoir utiliser axios directement (ce composnt upload ne sert à rien)
        // exemple: https://github.com/fengyuanchen/compressorjs
        const upload = new Upload({
          url: `${baseApiUrl}/Files`,
          form: compressedFile,
          headers: {
            [apiService.antiforgery.headerName]: apiService.antiforgery.requestToken,
          },
        });
      
        upload.on('progress', progress => {
          setProgress(progress * 100);
        });
      
        const response = await upload.upload();
        if (response.status != 200) {
          alert("Une erreur s'est produite");
        } else {
          if (typeof response.data !== "string") {
            throw new Error("Unexpected");
          }
          const data = JSON.parse(response.data) as ReadonlyArray<{ id: string }>;
          if (data.length != 1) {
            throw new Error("Unexpected");
          }
          if (!valueAccessor) {
            throw new Error("Unexpected");
          }
          valueAccessor.set([...valueAccessor.get(), data[0].id]);
        }
        setUploading(false);
        setProgress(0);
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files[0]) {
        handleFileAdd(files[0]);
        event.target.value = '';
      }
    };
    return <Box>
    <Typography variant="body1">
        {title}
    </Typography>
    <Box sx={{textAlign: "center"}}>
        {!valueAccessor &&
        <Box sx={{ display: 'flex', p: 1 }} justifyContent="center">
            <CircularProgress />
        </Box>}
        {valueAccessor &&
        <Box sx={{position: "relative"}}>
        <ImageList cols={columnCount}>
            {valueAccessor.get().map((x, i) => {
                const alt = `Photo n°${i}`;
                const url = `${baseApiUrl}/Files/${x}?fileName=${encodeURIComponent(`${alt} ${fileName}`.replace(/^\s+|\s+$/gm, ''))}`;
                return <ImageListItem key={i}>
                    <Paper sx={{p: 1}} variant="outlined">
                        <Box sx={{position: "relative"}}>
                            <Link href={url}
                            // target="_blank"
                            >
                                <img alt={alt} src={`${url}&thumbnail=xs`} loading="lazy"/>
                            </Link>
                            {!readonly && <IconButton aria-label="delete"
                                onClick={() => {
                                    const newValue = [...valueAccessor.get()];
                                    newValue.splice(i, 1);
                                    valueAccessor.set(newValue);
                                }}
                                sx={{
                                position: "absolute",
                                right: 0,
                                p: 0.3,
                                // borderWidth: 2,
                                // borderStyle: "solid",
                                // borderColor: (theme) => theme.palette.text.secondary,
                                // backgroundColor: (theme) => theme.palette.text.secondary
                            }}>
                                <Delete />
                            </IconButton>}
                        </Box>
                    </Paper>
                </ImageListItem>;
            })}
            <ImageListItem key="new">
                { uploading && <LinearProgress variant={progress > 0 ? 'determinate' : 'indeterminate'} value={progress} />}
            </ImageListItem>
        </ImageList>
        { !uploading && valueAccessor.get().length === 0 && <Typography variant="body2">
                    Aucun document
                </Typography>}
        {!uploading && !readonly && <StyledSpeedDial
            ariaLabel="ajout"
            icon={<SpeedDialIcon />}
        >
            <SpeedDialAction
                icon={<IconButton component="label" aria-label="photo" disabled={uploading}>
                <FileOpen />
                <VisuallyHiddenInput type="file" onChange={handleChange} />
            </IconButton>}
                tooltipTitle="Parcourir"
            />
            <SpeedDialAction
                icon={<IconButton component="label" aria-label="photo" disabled={uploading}>
                <PhotoCamera />
                <VisuallyHiddenInput type="file" accept="image/*" capture onChange={handleChange} />
            </IconButton>}
                tooltipTitle="Photo"
            />
        </StyledSpeedDial>}
        </Box>}
        {/* {!readonly && <Grid item xs={1} sx={{textAlign: "right"}}>
            <Stack>
                <IconButton component="label" aria-label="parcourir" disabled={uploading || !!valueAccessor.get()}>
                  <FileOpen />
                  <VisuallyHiddenInput type="file" onChange={handleChange} />
                </IconButton>
                <IconButton aria-label="supprimer" disabled={uploading || !valueAccessor.get()} onClick={() => valueAccessor.set(undefined)}>
                  <Delete />
                </IconButton>
            </Stack>
        </Grid>} */}
    </Box>
  </Box>;
}

export const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'fixed',
  zIndex: 1,
  bottom: theme.spacing(3),
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
}));