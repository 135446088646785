import { Divider, Grid, Typography } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { OutputBoolean } from "../../components/OutputBoolean";
import { OutputFloat } from "../../components/OutputFloat";
import { OutputInteger } from "../../components/OutputInteger";
import { OutputSelect } from "../../components/OutputSelect";
import { OutputText } from "../../components/OutputText";
import { BooleanToOptionValueAccessor, OptionalOptionValueAccessor, IntegerOptionValueAccessor, OptionValueAccessor } from "../../utils/ValueAccessor";
import { ViewKind, LogementPageOutletContext } from "../LogementPage";

export interface LogementTabContentProps {
    readonly viewKind: ViewKind;
}

export interface LogementDividerProps {
    text: string
}

export const LogementDivider = (props: LogementDividerProps) => {
    return (
        <Divider sx={{mb:1}} component="div" role="presentation">
            <Typography component="h2" variant="subtitle1" color="primary">{props.text}</Typography>
        </Divider>
    );
}

export const LogementTabContent = (props: LogementTabContentProps) => {
    const { logementAccessor, logementOptions, forceProgramme, forceBailleur } = useOutletContext() as LogementPageOutletContext;
    const loyerCCAccessor = (logementAccessor.loyerHC && logementAccessor.charges) ? {
        get() {
            let value = Math.round(((logementAccessor.loyerHC?.get() ?? 0) + (logementAccessor.charges?.get() ?? 0)) * 100) / 100;
            if (value === 0) {
                return undefined;
            }
            return value;
        },
        set(_value: number | undefined) {
            throw new Error("Unexpected");
        }
    } : undefined;
    return (<>
        <LogementDivider text="Identification"/>
        <Grid container spacing={1} marginBottom={1}>
            {props.viewKind == ViewKind.New && !forceBailleur &&
            <Grid item xs={6}>
                <OutputSelect
                    title="Bailleur"
                    required={false}
                    readonly={false}
                    valueAccessor={logementAccessor.bailleur
                        ? new IntegerOptionValueAccessor(
                            logementAccessor.bailleur,
                            logementOptions.bailleur)
                        : undefined}
                    />
            </Grid>}
            {props.viewKind == ViewKind.New && !forceProgramme &&
            <Grid item xs={12}>
                <OutputText
                    required={false}
                    readonly={false}
                    title="Programme"
                    valueAccessor={logementAccessor.nomProgramme}/>
            </Grid>}
            <Grid item xs={6}>
                <OutputSelect
                    title="Neuf ou existant"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.logementNeuf
                        ? new BooleanToOptionValueAccessor(
                            logementAccessor.logementNeuf, {
                                true: "Logement neuf",
                                false: "Logement existant"
                            })
                        : undefined}
                    />
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Etat d'avancement du projet"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.etatAvancementProjet
                        ? new OptionValueAccessor(
                            logementAccessor.etatAvancementProjet,
                            logementOptions.etatAvancementProjet)
                        : undefined}
                    />
            </Grid>
            <Grid item xs={6}>
                <OutputInteger
                    title="Année de livraison"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.anneeLivraison}/>
            </Grid>
            <Grid item xs={6}>
                <OutputInteger
                    title="Année de rotation"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.anneeRotation}/>
            </Grid>
            <Grid item xs={6}>
                <OutputInteger
                    title="Année de désignation"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.anneeDesignation}/>
            </Grid>
            <Grid item xs={4}>
                <OutputText
                    title="Bâtiment"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.numBatiment}/>
            </Grid>
            <Grid item xs={4}>
                <OutputText
                    title="Numéro"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.numLogement}/>
            </Grid>
            <Grid item xs={4}>
                <OutputText
                    title="Numéro RPLS"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.numRpls}/>
            </Grid>
            <Grid item xs={12}>
                <OutputText
                    title="Adresse"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.adresse}/>
                <OutputText
                    readonly={props.viewKind == ViewKind.Read}
                    required={false}
                    valueAccessor={logementAccessor.complementAdresse}/>
            </Grid>
            <Grid item xs={3}>
                <OutputText
                    title="Code postal"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.codePostal}/>
            </Grid>
            <Grid item xs={9}>
                <OutputSelect
                    title="Ville"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={(logementAccessor.commune && logementOptions.communes)
                        ? new OptionalOptionValueAccessor(
                            logementAccessor.commune,
                            logementOptions.getCommunes(logementAccessor.codePostal?.get()))
                        : undefined}
                    />
            </Grid>
        </Grid>
        <LogementDivider text="Statut"/>
        <Grid container spacing={1} marginBottom={1}>
            {/* <Grid item xs={12}>
                <OutputSelect
                    title="Bailleur"
                    required={false}
                    readonly={true}
                    valueAccessor={(logementAccessor.bailleur && logementOptions.bailleur)
                        ? new IntegerOptionValueAccessor(
                            logementAccessor.bailleur,
                            logementOptions.bailleur)
                        : undefined}/>
            </Grid> */}
            <Grid item xs={6}>
                <OutputSelect
                    title="Reservataire"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.reservataire
                        ? new OptionValueAccessor(
                            logementAccessor.reservataire,
                            logementOptions.reservataire)
                        : undefined}
                    />
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Financement"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.financement
                        ? new OptionValueAccessor(
                            logementAccessor.financement,
                            logementOptions.financement)
                        : undefined}
                    />
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Préconisations HandiToit"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.preconisationHanditoit
                        ? new BooleanToOptionValueAccessor(
                            logementAccessor.preconisationHanditoit, {
                                true: "Oui",
                                false: "Non"
                            })
                        : undefined}
                    helperText="Les préconisations HandiToit ont-elles été prises en compte lors de la construction ?"/>
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Label accessibilité"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.label
                        ? new OptionValueAccessor(
                            logementAccessor.label,
                            logementOptions.label)
                        : undefined}
                    helperText="Un label a-t-il été attribué par une collectivité ?"/>
                {logementAccessor.label?.get() === "Autre" &&
                <OutputText
                    title="Précision"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.labelAutre}/>}
            </Grid>
        </Grid>
        <LogementDivider text="Caractéristiques du logement"/>
        <Grid container spacing={1}  marginBottom={1}>
            <Grid item xs={12}>
                <OutputSelect
                    title="Suivi par l’architecte du Pôle Habitat HandiToit Provence"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.suiviParArchitecte
                        ? new BooleanToOptionValueAccessor(
                            logementAccessor.suiviParArchitecte, {
                                true: "Oui",
                                false: "Non"
                            })
                        : undefined}
                    />
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Caractéristique"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.caracteristique
                        ? new OptionValueAccessor(
                            logementAccessor.caracteristique,
                            logementOptions.caracteristique)
                        : undefined}
                />
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Nature"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.nature
                        ? new OptionValueAccessor(
                            logementAccessor.nature,
                            logementOptions.nature)
                        : undefined}
                    />
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Type"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.type
                        ? new OptionValueAccessor(
                            logementAccessor.type,
                            logementOptions.type)
                        : undefined}
                    />
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Niveau"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.niveau
                        ? new IntegerOptionValueAccessor(
                            logementAccessor.niveau,
                            logementOptions.niveau)
                        : undefined}
                    />
            </Grid>
            <Grid item xs={6}>
                <OutputText
                    title="Surface (en m2)"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.surface}/>
            </Grid>
            <Grid item xs={6}>
                <OutputSelect
                    title="Type de chauffage"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.chauffage
                        ? new OptionValueAccessor(
                            logementAccessor.chauffage,
                            logementOptions.chauffage)
                        : undefined}
                    />
            </Grid>
            <Grid item xs={12}>
                <OutputBoolean
                    title="Jardin"
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.jardin}/>
                <OutputBoolean
                    title="Balcon"
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.balcon}/>
                <OutputBoolean
                    title="Terrasse"
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.terrasse}/>
                <OutputBoolean
                    title="Loggia"
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.loggia}/>
                <OutputBoolean
                    title="Garage individuel"
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.garage}/>
                <OutputBoolean
                    title="Emplacement parking individuel"
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.parking}/>
            </Grid>
            {(logementAccessor.garage?.get() || logementAccessor.parking?.get()) &&
            <Grid item xs={12}>
                <OutputText
                    title="Prix du garage ou de l'emplacement"
                    required={true}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.prixParking}/>
            </Grid>}
        </Grid>
        <LogementDivider text="Descriptif du loyer"/>
        <Grid container spacing={1} marginBottom={1}>
            <Grid item xs={6}>
                <OutputFloat
                    title="Loyer mensuel (HC)"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.loyerHC}/>
            </Grid>
            <Grid item xs={6}>
                <OutputFloat
                    title="Charges mensuelles"
                    required={false}
                    readonly={props.viewKind == ViewKind.Read}
                    valueAccessor={logementAccessor.charges}/>
            </Grid>
            <Grid item xs={12}>
                <OutputFloat
                    title="Loyer (CC)"
                    required={false}
                    readonly={true}
                    valueAccessor={loyerCCAccessor}/>
            </Grid>
        </Grid>
    </>);
};