/* Generated file */

import { LogementAccessor } from '../../services/LogementAccessor';
import { DiagQuestionDescription } from '../../utils/DiagQuestionDescription';
import { Logement } from '../../dataModel/generated/Logement';
import { DiagnosticReponse } from '../../dataModel/generated/DiagnosticReponse';

export const diagnosticQuestions: Readonly<Record<string, DiagQuestionDescription>> = {
	chemExtConformeReglementation: {
		label: "PERMIS SIGNÉ APRÈS 2007 : conforme à la réglementation",
	},
	chemExtSansMarchesNiRessauts: {
		label: "Cheminement accessible sans marches ni ressauts ni pentes",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non";
		},
	},
	chemExtRessaut: {
		label: "Ressaut ≤ à 2cm arrondi ou chanfreiné\nRessaut ≤ à 4cm arrondi si chanfreiné à 33%",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non" &&
				accessor.chemExtSansMarchesNiRessauts?.get() === "Non";
		},
	},
	chemExtPente: {
		label: "Pente ≤ à 5% sur 10 mètres maximum\nPente ≤ à 8% sur 2 mètres maximum\nPente ≤ à 10% sur 0,5 mètres maximum",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non" &&
				accessor.chemExtSansMarchesNiRessauts?.get() === "Non";
		},
	},
	chemExtPalier: {
		label: "Présence de palier de repos en bas et en haut de la pente",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non" &&
				accessor.chemExtSansMarchesNiRessauts?.get() === "Non";
		},
	},
	chemExtBordure: {
		label: "Bordure de 5cm le long du cheminement",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non" &&
				accessor.chemExtSansMarchesNiRessauts?.get() === "Non";
		},
	},
	chemExtRampe: {
		label: "Présence d'une rampe d'accès",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non" &&
				accessor.chemExtSansMarchesNiRessauts?.get() === "Non";
		},
	},
	chemExtMainCourante: {
		label: "Présence d'une maincourante le long des pentes supérieurs à 4%",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non" &&
				accessor.chemExtSansMarchesNiRessauts?.get() === "Non";
		},
	},
	chemExtLargeurSup1m20: {
		label: "Largeur du cheminement ≥ à 1m20",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non";
		},
	},
	chemExtLargeurSup1m60: {
		label: "Largeur du cheminement ≥ à 1m60",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non";
		},
	},
	chemExtSolNonMeuble: {
		label: "Sol non meuble, non réfléchissant, sans obstacles",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non";
		},
	},
	chemExtTrou: {
		label: "Largeur ou diamètre ≤ 2cm\nFentes perpendiculaires au cheminement",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non" &&
				accessor.chemExtSolNonMeuble?.get() === "Non";
		},
	},
	chemExtObstacle: {
		label: "Passage libre ≥ 2,20m du dessus du sol si obstacle suspendu au-dessus du cheminement\nContraste visuel et rappel tactile si obstacle implanté sur cheminement ou saillie latérale ≥ 15cm",
		condition: (accessor: LogementAccessor) => {
			return accessor.chemExtConformeReglementation?.get() === "Non" &&
				accessor.chemExtSolNonMeuble?.get() === "Non";
		},
	},
	chemExtInfo: {
		label: "Commentaire",
		isTextarea: true,
	},
	communPorteVoie: {
		label: "La porte du hall principal donne sur la voie publique",
	},
	communPorteResidence: {
		label: "La porte du hall principal donne dans la résidence",
	},
	communPorteAuto: {
		label: "Porte d'entrée automatisée",
		scoreLevel: 3
	},
	communPorteTele: {
		label: "Une télécommande permettant le déclenchement de la porte automatique est fournie aux locataires des appartements adaptés",
	},
	communOuvManu: {
		label: "Ouverture par commande manuelle",
	},
	communDispoHaut: {
		label: "Les dispositifs sont situés à une hauteur de 0,90m du sol",
	},
	communDispoAngle: {
		label: "Les dispositifs sont situés à 0,40m d'un angle rentrant",
	},
	communDispoSonore: {
		label: "Un signal sonore et visuel est déclenché par le fonctionnement des dispositifs",
	},
	communDispoCamera: {
		label: "Les appareils d'interphonie sont munis d'une caméra (permettant de visualiser ses visiteurs)",
	},
	communDispoSansFil: {
		label: "L'interphone dispose d'un système de communication sans fil vers GSM",
		scoreLevel: 2
	},
	communHallSup1m20: {
		label: "Largeur des circulations horizontales internes > 1,20m",
	},
	communHallSup1m50: {
		label: "Largeur des circulations horizontales internes > 1,50m",
	},
	communHallColectif: {
		label: "Les locaux collectifs (cave, cellier etc.) sont accessibles",
	},
	communHallEclairageAuto: {
		label: "L'éclairage se déclenche grâce à des détecteurs de présence",
		scoreLevel: 2
	},
	communHallEclairage90: {
		label: "Les commandes d'éclairage manuelles sont situées à 0,90m",
	},
	communHallEclairageDiode: {
		label: "Les commandes d'éclairage manuelles sont dotées d'une diode lumineuse",
		scoreLevel: 1
	},
	communAscenseurBouton: {
		label: "Bouton de commande situé à 0,90 m du sol fini et à 0,40 m d'un angle",
		scoreLevel: 1
	},
	communAscenseurNorme: {
		label: "Ascenseur conforme à la norme NF EN 81-70 (dimension intérieure de la cabine : 1,10 m x 1,40 m)",
		scoreLevel: 1
	},
	communAscenseurHori: {
		label: "Les commandes sont horizontales",
		scoreLevel: 1
	},
	communBoiteLettre: {
		label: "Les boites aux lettres des logements adaptés sont situées entre 0,40m et 1,30m",
		scoreLevel: 1
	},
	communInfo: {
		label: "Commentaire",
		isTextarea: true,
	},
	entreeLargeur: {
		label: "Largeur minimale de 0,90m",
	},
	entreeSerrure: {
		label: "Serrure située à plus de 0,30 m d'un angle rentrant de paroi ou de tout autre obstacle",
	},
	entreePoignee: {
		label: "L'extrémité de la poignée est située à 0,40m au moins d'un angle de paroi ou de tout autre obstacle",
	},
	entreeEspaceManoeuvre: {
		label: "Espace de manoeuvre de porte respecté\n(Largeur circulation x 1,70m en poussant; Largeur circulant x 2,20m en tirant)",
	},
	entreeAuto: {
		label: "La porte pourra être automatisée\n(Présence d'un fourreau et d'un câble d'alimentation au-dessus de la porte et dans l'axe de celle-ci près de la serrure)",
		scoreLevel: 3
	},
	entreeEclairage: {
		label: "Une commande d'éclairage permettant d'éteindre l'ensemble des sources lumineuses de l'appartement est positionnée à proximité de la porte d'entrée",
		scoreLevel: 2
	},
	dispositifHauteur130: {
		label: "Tous les dispositifs de commande sont situés à une hauteur comprise entre 0,90 m et 1,30 m du sol",
	},
	dispositifHanteur90: {
		label: "Tous les dispositifs de commande sont situés à 0,90 m du sol",
		scoreLevel: 1
	},
	dispositifAngle: {
		label: "Tous les dispositifs de commande sont dégagés de 0,40 m de tout angle",
	},
	dispositifVoletElec: {
		label: "Tous les volets sont à commande électrique",
		scoreLevel: 2
	},
	dispositifManoeuvreSecours: {
		label: "Présence d'une manoeuvre manuelle de secours pour les volets à 0,90m du sol",
		scoreLevel: 1
	},
	generalLargeurSup90: {
		label: "La largeur des couloirs est comprise entre 0,90m et 1,20m",
	},
	generalLargeurSup120: {
		label: "La largeur des couloirs est supérieure à 1,20m",
	},
	generalLargeurPorte80: {
		label: "Largeur porte intérieure ≥ 0,80",
	},
	generalLargeurPorte90: {
		label: "Largeur porte intérieure ≥ 0,90",
		scoreLevel: 2
	},
	generalPoignetPorte: {
		label: "L'extrémité des poignets des portes intérieures sont situées à 0,40 m minimum d'un angle de paroi ou de tout autre obstacle à l'approche d'un fauteuil roulant",
		scoreLevel: 2
	},
	generalEspaceManoeuvre: {
		label: "Espace de manoeuvre de porte respecté\n(Largeur circulation x 1,70m en poussant; Largeur circulant x 2,20m en tirant)",
		scoreLevel: 2
	},
	cuisinePassage: {
		label: "Passage d'une largeur minimale de 1,50 m entre les espaces ménagers",
	},
	cuisineEspace: {
		label: "Espace libre sous l'évier > 0,80 m de largeur et 0,70 m de hauteur",
		scoreLevel: 1
	},
	cuisineProfondeur: {
		label: "Profondeur de l'évier : Maximum 0,15 cm",
		scoreLevel: 1
	},
	cuisineSiphon: {
		label: "Siphon déporté vers le mur",
		scoreLevel: 1
	},
	cuisineCuve: {
		label: "Si cuve en inox : siphon et cuve isolés",
		scoreLevel: 1
	},
	terrasseAcces: {
		label: "Accès aux portes fenêtres avec un vantail ≥ à 0,90cm",
		scoreLevel: 2
	},
	terrasseRessaut: {
		label: "Ressauts intérieurs et extérieurs < à 2cm",
		scoreLevel: 2
	},
	chambreDiametre: {
		label: "Espace disponible > 1,50 m de diamètre (en dehors du débattement de la porte et de l'emprise du lit)",
	},
	chambrePassage: {
		label: "Passage libre ≥ à 0,90 m sur les deux grands côtés du lit et ≥ à 1,20 m sur le petit côté du lit\nOU Passage libre ≥ à 1,20 m sur les deux grands côtés du lit et ≥ à 0,90 m sur le petit côté du lit",
	},
	chambreLargeurPorte80: {
		label: "Largeur porte intérieure ≥ 0,80",
	},
	chambreLargeurPorte90: {
		label: "Largeur porte intérieure ≥ 0,90",
		scoreLevel: 2
	},
	chambrePoignetPorte: {
		label: "L'extrémité des poignets portes intérieurs sont situées à 0,40 m minimum d'un angle de paroi ou de tout autre obstacle à l'approche d'un fauteuil roulant",
		scoreLevel: 2
	},
	chambreEspaceManoeuvre: {
		label: "Espace de manoeuvre de porte respecté\n(Largeur circulation x 1,70m en poussant; Largeur circulant x 2,20m en tirant)",
		scoreLevel: 2
	},
	salleBainEspace: {
		label: "Espace libre d'au moins 1,50m de diamètre en dehors de la porte et des équipements fixes",
	},
	bainLargeurPorte80: {
		label: "Largeur porte intérieure ≥ 0,80",
	},
	bainLargeurPorte90: {
		label: "Largeur porte intérieure ≥ 0,90",
		scoreLevel: 2
	},
	bainPoignetPorte: {
		label: "L'extrémité des poignets portes intérieurs sont situées à 0,40 m minimum d'un angle de paroi ou de tout autre obstacle à l'approche d'un fauteuil roulant",
		scoreLevel: 2
	},
	bainEspaceManoeuvre: {
		label: "Espace de manoeuvre de porte respecté\n(Largeur circulation x 1,70m en poussant; Largeur circulant x 2,20m en tirant)",
		scoreLevel: 2
	},
	doucheItalienne: {
		label: "Douche à l'italienne (siphon de sol)",
		condition: (accessor: LogementAccessor) => {
			return accessor.doucheReceveurEncastre?.get() !== "Oui";
		},
	},
	doucheItalienneOk: {
		label: "Si douche à l'italienne :\n-	Sol antidérapant\n-	Pente facilitant l'écoulement des eaux sur la totalité du sol de la pièce d'eau",
		condition: (accessor: LogementAccessor) => {
			return accessor.doucheItalienne?.get() === "Oui";
		},
		scoreLevel: 3
	},
	doucheReceveurEncastre: {
		label: "Receveur encastré extra-plat sans seuil",
		condition: (accessor: LogementAccessor) => {
			return accessor.doucheItalienne?.get() !== "Oui";
		},
	},
	doucheReceveurEncastreOk: {
		label: "Si receveur encastré extra-plat sans seuil :surface > 0,90 m x 0,90 m ou 1 m x 0,80 m",
		condition: (accessor: LogementAccessor) => {
			return accessor.doucheReceveurEncastre?.get() === "Oui";
		},
		scoreLevel: 2
	},
	doucheParois: {
		label: "Absence de parois dans la surface de l'espace de douche",
	},
	doucheBarreAppui: {
		label: "Présence d'une barre d'appui",
	},
	doucheSiege: {
		label: "Présence siège douche",
	},
	doucheCloisons: {
		label: "Cloisons périphériques renforcées sur une largeur de 1,50 m et une hauteur de 2m permettant la pose de barres de transfert",
		scoreLevel: 1
	},
	lavaboAdapte: {
		label: "Lavabo adapté",
	},
	lavaboLibreSous: {
		label: "Espace libre sous le lavabo supérieur à 70 cm",
		condition: (accessor: LogementAccessor) => {
			return accessor.lavaboAdapte?.get() === "Oui";
		},
	},
	lavaboProfondeur: {
		label: "Profondeur (du mur au bord du lavabo) < 60 cm",
		condition: (accessor: LogementAccessor) => {
			return accessor.lavaboAdapte?.get() === "Oui";
		},
	},
	lavaboHauteur: {
		label: "Hauteur du lavabo comprise entre 80 et 85cm",
		condition: (accessor: LogementAccessor) => {
			return accessor.lavaboAdapte?.get() === "Oui";
		},
		scoreLevel: 1
	},
	lavaboHauteurReglable: {
		label: "La hauteur est réglable",
		condition: (accessor: LogementAccessor) => {
			return accessor.lavaboAdapte?.get() === "Oui";
		},
		scoreLevel: 2
	},
	lavaboSiphon: {
		label: "Le siphon est déporté vers le mur",
		condition: (accessor: LogementAccessor) => {
			return accessor.lavaboAdapte?.get() === "Oui";
		},
		scoreLevel: 2
	},
	wcEspace: {
		label: "Espace libre d'au moins 0,80 m x 1,30 m latéralement à la cuvette en dehors du débattement de la porte",
	},
	wcCloison: {
		label: "Cloisons périphériques renforcées sur une largeur de 1,50 m et une hauteur de 2m permettant la pose de barres de transfert",
		scoreLevel: 1
	},
	wcHauteur: {
		label: "Hauteur de la cuvette ≥ 47 cm",
		scoreLevel: 1
	},
	wcSuspendu: {
		label: "La cuvette est suspendue",
		scoreLevel: 1
	},
	wcBarreAppui: {
		label: "Présence d’une barre d’appui",
	},
	wcLargeurPorte80: {
		label: "Largeur porte intérieure ≥ 0,80",
	},
	wcLargeurPorte90: {
		label: "Largeur porte intérieure ≥ 0,90",
		scoreLevel: 2
	},
	wcPoignetPorte: {
		label: "L'extrémité des poignets portes intérieurs sont situées à 0,40 m minimum d'un angle de paroi ou de tout autre obstacle à l'approche d'un fauteuil roulant",
		scoreLevel: 2
	},
	wcEspaceManoeuvre: {
		label: "Espace de manoeuvre de porte respecté\n(Largeur circulation x 1,70m en poussant; Largeur circulant x 2,20m en tirant)",
		scoreLevel: 2
	},
	logementInfo: {
		label: "Commentaire",
		isTextarea: true,
	},
	statioDedie: {
		label: "L'appartement a une place de stationnement adaptée dédiée",
	},
	statioProxi: {
		label: "Les places sont situées à proximité immédiate des entrées d'immeuble",
	},
	statioSousSol: {
		label: "Les places sont situées en sous-sol",
	},
	statioSurface: {
		label: "Les places sont situées en surface",
	},
	statioVisiteur: {
		label: "Le stationnement visiteur comporte des places adaptées",
	},
	statioSasInt: {
		label: "Les deux portes s'ouvrent à l'intérieur du SAS",
	},
	statioSasSup1m20: {
		label: "Dimensions intérieures > 1,20 m x 3,10 m",
	},
	statioSasSup1m50: {
		label: "Dimensions intérieures > 1,50 m x 3,10 m",
	},
	statioSasOculus: {
		label: "Présence d'un oculus sur la porte",
	},
	statioInfo: {
		label: "Commentaire",
		isTextarea: true,
	},
};

export const normalizeDiagnostic = (logement: Logement): Logement => {
	const normalized = {...logement};
	normalized.chemExtSansMarchesNiRessauts = normalized.chemExtConformeReglementation === "Non" ? normalized.chemExtSansMarchesNiRessauts : undefined;
	normalized.chemExtRessaut = normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non" ? normalized.chemExtRessaut : undefined;
	normalized.chemExtPente = normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non" ? normalized.chemExtPente : undefined;
	normalized.chemExtPalier = normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non" ? normalized.chemExtPalier : undefined;
	normalized.chemExtBordure = normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non" ? normalized.chemExtBordure : undefined;
	normalized.chemExtRampe = normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non" ? normalized.chemExtRampe : undefined;
	normalized.chemExtMainCourante = normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non" ? normalized.chemExtMainCourante : undefined;
	normalized.chemExtLargeurSup1m20 = normalized.chemExtConformeReglementation === "Non" ? normalized.chemExtLargeurSup1m20 : undefined;
	normalized.chemExtLargeurSup1m60 = normalized.chemExtConformeReglementation === "Non" ? normalized.chemExtLargeurSup1m60 : undefined;
	normalized.chemExtSolNonMeuble = normalized.chemExtConformeReglementation === "Non" ? normalized.chemExtSolNonMeuble : undefined;
	normalized.chemExtTrou = normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSolNonMeuble === "Non" ? normalized.chemExtTrou : undefined;
	normalized.chemExtObstacle = normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSolNonMeuble === "Non" ? normalized.chemExtObstacle : undefined;
	normalized.doucheItalienne = normalized.doucheReceveurEncastre !== "Oui" ? normalized.doucheItalienne : undefined;
	normalized.doucheItalienneOk = normalized.doucheItalienne === "Oui" ? normalized.doucheItalienneOk : undefined;
	normalized.doucheReceveurEncastre = normalized.doucheItalienne !== "Oui" ? normalized.doucheReceveurEncastre : undefined;
	normalized.doucheReceveurEncastreOk = normalized.doucheReceveurEncastre === "Oui" ? normalized.doucheReceveurEncastreOk : undefined;
	normalized.lavaboLibreSous = normalized.lavaboAdapte === "Oui" ? normalized.lavaboLibreSous : undefined;
	normalized.lavaboProfondeur = normalized.lavaboAdapte === "Oui" ? normalized.lavaboProfondeur : undefined;
	normalized.lavaboHauteur = normalized.lavaboAdapte === "Oui" ? normalized.lavaboHauteur : undefined;
	normalized.lavaboHauteurReglable = normalized.lavaboAdapte === "Oui" ? normalized.lavaboHauteurReglable : undefined;
	normalized.lavaboSiphon = normalized.lavaboAdapte === "Oui" ? normalized.lavaboSiphon : undefined;
	return normalized;
};

export const scoreRules = [[
	(logement: LogementAccessor) => logement.communHallEclairageDiode?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.communAscenseurBouton?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.communAscenseurNorme?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.communAscenseurHori?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.communBoiteLettre?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.dispositifHanteur90?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.dispositifManoeuvreSecours?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.cuisineEspace?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.cuisineProfondeur?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.cuisineSiphon?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.cuisineCuve?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.doucheCloisons?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.lavaboHauteur?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.wcCloison?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.wcHauteur?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.wcSuspendu?.get() === DiagnosticReponse.Oui,
],[
	(logement: LogementAccessor) => logement.communDispoSansFil?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.communHallEclairageAuto?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.entreeEclairage?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.dispositifVoletElec?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.generalLargeurPorte90?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.generalPoignetPorte?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.generalEspaceManoeuvre?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.terrasseAcces?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.terrasseRessaut?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.chambreLargeurPorte90?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.chambrePoignetPorte?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.chambreEspaceManoeuvre?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.bainLargeurPorte90?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.bainPoignetPorte?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.bainEspaceManoeuvre?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.doucheReceveurEncastreOk?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.lavaboHauteurReglable?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.lavaboSiphon?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.wcLargeurPorte90?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.wcPoignetPorte?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.wcEspaceManoeuvre?.get() === DiagnosticReponse.Oui,
],[
	(logement: LogementAccessor) => logement.communPorteAuto?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.entreeAuto?.get() === DiagnosticReponse.Oui,
	(logement: LogementAccessor) => logement.doucheItalienneOk?.get() === DiagnosticReponse.Oui,
],];

export const diagnosticSections = [{
	label: "Cheminements extérieurs de l'entrée de la résidence à l'immeuble",
	children: [
		"chemExtConformeReglementation",
		{
			title: "Pente et ressaut",
			condition: (accessor: LogementAccessor) => {
				return accessor.chemExtConformeReglementation?.get() === "Non";
			}
		},
		"chemExtSansMarchesNiRessauts",
		{
			title: "Si ressaut",
			condition: (accessor: LogementAccessor) => {
				return accessor.chemExtConformeReglementation?.get() === "Non" &&
					accessor.chemExtSansMarchesNiRessauts?.get() === "Non";
			}
		},
		"chemExtRessaut",
		{
			title: "Si denivelé",
			condition: (accessor: LogementAccessor) => {
				return accessor.chemExtConformeReglementation?.get() === "Non" &&
					accessor.chemExtSansMarchesNiRessauts?.get() === "Non";
			}
		},
		"chemExtPente",
		"chemExtPalier",
		"chemExtBordure",
		"chemExtRampe",
		"chemExtMainCourante",
		{
			title: "Croisement et circulation extérieure",
			condition: (accessor: LogementAccessor) => {
				return accessor.chemExtConformeReglementation?.get() === "Non";
			}
		},
		"chemExtLargeurSup1m20",
		"chemExtLargeurSup1m60",
		{
			title: "Qualité du cheminement",
			condition: (accessor: LogementAccessor) => {
				return accessor.chemExtConformeReglementation?.get() === "Non";
			}
		},
		"chemExtSolNonMeuble",
		{
			title: "Si trous et fentes",
			condition: (accessor: LogementAccessor) => {
				return accessor.chemExtConformeReglementation?.get() === "Non" &&
					accessor.chemExtSolNonMeuble?.get() === "Non";
			}
		},
		"chemExtTrou",
		{
			title: "Si obstacles",
			condition: (accessor: LogementAccessor) => {
				return accessor.chemExtConformeReglementation?.get() === "Non" &&
					accessor.chemExtSolNonMeuble?.get() === "Non";
			}
		},
		"chemExtObstacle",
		"chemExtInfo",
	],
}, {
	label: "Equipement des parties communes",
	children: [
		{
			title: "Porte d'entrée d'immeuble",
		},
		"communPorteVoie",
		"communPorteResidence",
		"communPorteAuto",
		"communPorteTele",
		"communOuvManu",
		{
			title: "Système de commande et de communication (visiophone, interphone)",
		},
		"communDispoHaut",
		"communDispoAngle",
		"communDispoSonore",
		"communDispoCamera",
		"communDispoSansFil",
		{
			title: "Hall d'entrée immeuble",
		},
		"communHallSup1m20",
		"communHallSup1m50",
		"communHallColectif",
		"communHallEclairageAuto",
		"communHallEclairage90",
		"communHallEclairageDiode",
		{
			title: "Ascenseur",
		},
		"communAscenseurBouton",
		"communAscenseurNorme",
		"communAscenseurHori",
		{
			title: "Boîtes aux lettres",
		},
		"communBoiteLettre",
		"communInfo",
	],
}, {
	label: "Le logement",
	children: [
		{
			title: "Porte d'entrée",
		},
		"entreeLargeur",
		"entreeSerrure",
		"entreePoignee",
		"entreeEspaceManoeuvre",
		"entreeAuto",
		"entreeEclairage",
		{
			title: "Dispositifs de commande (y compris tableau électrique et thermostat)",
		},
		"dispositifHauteur130",
		"dispositifHanteur90",
		"dispositifAngle",
		"dispositifVoletElec",
		"dispositifManoeuvreSecours",
		{
			title: "Général / séjour",
		},
		"generalLargeurSup90",
		"generalLargeurSup120",
		"generalLargeurPorte80",
		"generalLargeurPorte90",
		"generalPoignetPorte",
		"generalEspaceManoeuvre",
		{
			title: "Cuisine",
		},
		"cuisinePassage",
		"cuisineEspace",
		"cuisineProfondeur",
		"cuisineSiphon",
		"cuisineCuve",
		{
			title: "Terrasse",
		},
		"terrasseAcces",
		"terrasseRessaut",
		{
			title: "Chambre adaptée",
		},
		"chambreDiametre",
		"chambrePassage",
		"chambreLargeurPorte80",
		"chambreLargeurPorte90",
		"chambrePoignetPorte",
		"chambreEspaceManoeuvre",
		{
			title: "Salle de bains",
		},
		"salleBainEspace",
		"bainLargeurPorte80",
		"bainLargeurPorte90",
		"bainPoignetPorte",
		"bainEspaceManoeuvre",
		{
			title: "Douche",
		},
		"doucheItalienne",
		"doucheItalienneOk",
		"doucheReceveurEncastre",
		"doucheReceveurEncastreOk",
		"doucheParois",
		"doucheBarreAppui",
		"doucheSiege",
		"doucheCloisons",
		{
			title: "Lavabo",
		},
		"lavaboAdapte",
		"lavaboLibreSous",
		"lavaboProfondeur",
		"lavaboHauteur",
		"lavaboHauteurReglable",
		"lavaboSiphon",
		{
			title: "WC",
		},
		"wcEspace",
		"wcCloison",
		"wcHauteur",
		"wcSuspendu",
		"wcBarreAppui",
		"wcLargeurPorte80",
		"wcLargeurPorte90",
		"wcPoignetPorte",
		"wcEspaceManoeuvre",
		"logementInfo",
	],
}, {
	label: "Stationnements collectifs et sas",
	children: [
		{
			title: "Stationnement occupant",
		},
		"statioDedie",
		"statioProxi",
		"statioSousSol",
		"statioSurface",
		{
			title: "Stationnement visiteur",
		},
		"statioVisiteur",
		{
			title: "Sas d'isolement",
		},
		"statioSasInt",
		"statioSasSup1m20",
		"statioSasSup1m50",
		"statioSasOculus",
		"statioInfo",
]}];
