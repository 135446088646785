import { scoreRules } from "../pages/generated/DiagnosticQuestions";
import { LogementAccessor } from "../services/LogementAccessor";

export const computeHandiToitScore = (logementAccessor: LogementAccessor): number => {
    const scores: number[] = [];
    for (let i = 0;i < scoreRules.length; i++)
    {
        const rules = scoreRules[i];
        let score = 0;
        for (const r of rules)
        {
            score += r(logementAccessor) ? 1 : 0;
        }
        scores[i] = score;
    }
    /*
    * 3 *** si : au moins 2 *** + 10 ** + 11 *
    * 2 ** si : au moins 10 ** + 11 *
    * 1 * si : au moins 12 *
    */
    const score3 = scores[2];
    const score2 = score3 + scores[1];
    const score1 = score2 + scores[0];
    let result = 0;
    if (score1 >= 12)
    {
        result = 1;
    }
    if (score2 >= 10)
    {
        if (score1 - 10 >= 11)
        {
            result = 2;
        }
    }
    if (score3 >= 2)
    {
        if (score2 - 2 >= 10)
        {
            if (score1 - 10 - 2 >= 11)
            {
                result = 3;
            }
        }
    }
    return result;
}