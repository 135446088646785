import { Alert, Backdrop, Button, CircularProgress, Link, Stack, TextField } from "@mui/material";
import { IApiService } from "../services/ApiService";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { PasswordInput } from "../components/PasswordInput";
import { Check } from "@mui/icons-material";

export interface ResetPasswordPageProps {
    readonly api: IApiService;
}

export const ResetPasswordPage = (props: ResetPasswordPageProps) => {
    const { api } = props;
    const location = useLocation();
    const code = new URLSearchParams(location.search).get("code") ?? "";
    const [email, setEmail] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmedPassword, setConfirmedPassword] = useState<string>("");
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [errors, setErrors] = useState<Readonly<{[key: string]: ReadonlyArray<string>}> | undefined>(undefined)
    const [done, setDone] = useState(false);
    const formEnabled = email.trim() && newPassword.trim() && newPassword.trim() === confirmedPassword.trim() && !inProgress;
    const handleClick = async () => {
        if (!formEnabled) {
            return;
        }
        if (!email?.trim()) {
            throw new Error("Unexpected");
        }
        setInProgress(true);
        var errors = await api.resetPassword(email.trim(), newPassword.trim(), confirmedPassword.trim(), code);
        setInProgress(false);
        if (errors) {
            setErrors(errors);
        } else {
            setErrors(undefined);
            setDone(true);
        }
    }
    return <>
        {errors && <Alert severity="error">
            {Object.entries(errors).map(x => x[1].map(error => <>
                {error}<br/>
            </>))}
        </Alert>}
        <Backdrop open={inProgress}>
            <CircularProgress color="inherit" />
        </Backdrop>
        {!done && <Stack 
            spacing={2}
            sx={{mt:5}}>
            <TextField
                onChange={(ev) => setEmail(ev.currentTarget.value)}
                margin="normal"
                required
                fullWidth
                label="Adresse email"
                autoComplete="email"
                autoFocus
            />
            <PasswordInput
                label="Nouveau mot de passe"
                password={newPassword}
                handlePassword={(ev) => setNewPassword(ev.currentTarget.value)}
                autoComplete="new-password"
            />
            <PasswordInput
                label="Confirmation du nouveau mot de passe"
                password={confirmedPassword}
                handlePassword={(ev) => setConfirmedPassword(ev.currentTarget.value)}
                autoComplete="new-password"
                error={newPassword?.trim() !== confirmedPassword?.trim()}
                helperText={newPassword?.trim() !== confirmedPassword?.trim() ? "Le texte saisie ne correspond pas au nouveau mot de passe" : undefined}
            />
            <Button
                onClick={() => handleClick()}
                disabled={!formEnabled}
                type="submit"
                fullWidth
                variant="contained">
                Enregistrer le nouveau mot de passe
            </Button>
        </Stack>}
        {done && <><Alert icon={<Check fontSize="inherit" />} severity="success">
            Le mot de passe a bien été mis à jour
        </Alert>
        <Link href="/account/login" variant="body2">
            Se connecter
        </Link></>}
    </>;
}