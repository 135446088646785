import { Backdrop, Box, Button, CircularProgress, Stack, TextareaAutosize, Typography } from "@mui/material";
import { LogementPageOutletContext, ViewKind } from "../LogementPage"
import { Navigate, useOutletContext } from "react-router-dom";
import { IApiService } from "../../services/ApiService";
import { useState } from "react";
import { useConfirm } from "material-ui-confirm";

export interface SaveTabContent {
    readonly viewKind: ViewKind;
    readonly apiService: IApiService;
}

export const SaveTabContent = (props: SaveTabContent) => {
    const { logementAccessor, currentLogement, setInitialLogement } = useOutletContext() as LogementPageOutletContext;
    const [busy, setBusy] = useState(false);
    const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined);
    const confirm = useConfirm();
    const handleDelete = async () => {
        if (!currentLogement) {
            return;
        }
        confirm({
            cancellationText: "Non",
            confirmationText: "Oui",
            title: "Suppression",
            description: "Etes vous sur de vouloir supprimer ce logement ?"
        }).then(() => {
            setBusy(true);
            props.apiService.deleteLogement(currentLogement.id, new AbortController().signal)
            .then(() => {
                setNavigateTo("/");
            });
        }).catch(() => {
            // canceled
        });
    };
    const handleCancel = () => {
        if (!currentLogement) {
            return;
        }
        setInitialLogement(currentLogement);
        setNavigateTo(`/logement/${currentLogement.id}/view`);
    };
    const handleSave = async () => {
        if (!currentLogement) {
            return;
        }
        setBusy(true);
        const newLogement = await props.apiService.upsertLogement(currentLogement, new AbortController().signal);
        const id = newLogement?.id ?? currentLogement.id;
        setInitialLogement(currentLogement);
        setNavigateTo(`/logement/${id}/view`);
    };
    return (<>
        <Backdrop
            sx={{
                color: (theme) => theme.palette.background.default,
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={busy}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        {navigateTo !== undefined && <Navigate to={navigateTo}/>}
        {navigateTo === undefined && <Stack sx={{mb: 1}}>
            <Typography component="h3" variant="subtitle2" sx={{mt: 1, mb: 1}}>
                Commentaire d'historique
            </Typography>
            <Box>
                <TextareaAutosize
                    minRows={7}
                    value={logementAccessor.comment?.get() ?? ""}
                    onChange={(ev) => logementAccessor.comment?.set(ev.target.value)}
                    style={{width: "100%", resize: "none"}}
                />
            </Box>
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{mt: 1, mb: 1}}>
                <Button variant="outlined" onClick={handleCancel}>Annuler</Button>
                <Button variant="outlined" onClick={handleSave}>Enregistrer</Button>
            </Stack>
            {props.apiService.user && !props.apiService.user.bailleurId && <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{mt: 1, mb: 1}}>
                <Button variant="outlined" color="error" onClick={handleDelete}>Supprimer le logement</Button>
            </Stack>}
        </Stack>}
    </>);
}