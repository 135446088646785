import { Option, UndefinedOption } from "./LogementOptions";

export interface IValueGetter<TValue> {
    get(): TValue;
}

export interface IValueAccessor<TValue> extends IValueGetter<TValue> {
    set(value: TValue): void;
}

export class ValueAccessor<
    TValue,
    TKey extends string,
    TParent extends Readonly<Record<TKey, TValue>>
> implements IValueAccessor<TValue> {
    private readonly parent: TParent;
    private readonly key: TKey;
    private readonly setter: (newParent: TParent) => void;
    constructor(
        parent: TParent,
        key: TKey,
        setter: (newParent: TParent) => void
    ) {
        this.parent = parent;
        this.key = key;
        this.setter = setter;
    }
    public get(): TValue {
        return this.parent[this.key];
    }
    public set(value: TValue): void {
        this.setter({
            ...this.parent,
            [this.key]: value
        });
    }
}

export interface IOptionValueAccessor extends IValueAccessor<string> {
    readonly options: ReadonlyArray<Option<string>>;
}

export class OptionValueAccessor implements IOptionValueAccessor {
    readonly internal: IValueAccessor<string>;
    readonly options: ReadonlyArray<Option<string>>;
    constructor(
        internal: IValueAccessor<string>,
        options: ReadonlyArray<Option<string>>
    ) {
        this.internal = internal;
        this.options = options;
    }
    public get(): string {
        return this.internal.get();
    }
    public set(value: string): void {
        this.internal.set(value);
    }
}

export class OptionalOptionValueAccessor implements IOptionValueAccessor {
    readonly internal: IValueAccessor<string | undefined>;
    readonly options: ReadonlyArray<Option<string>>;
    constructor(
        internal: IValueAccessor<string | undefined>,
        options: ReadonlyArray<Option<string>>
    ) {
        this.internal = internal;
        this.options = [UndefinedOption, ...options];
    }
    public get(): string {
        const value = this.internal.get();
        if (value === undefined || value === null) {
            return UndefinedOption.value;
        }
        return value;
    }
    public set(value: string): void {
        if (value === UndefinedOption.value) {
            this.internal.set(undefined);
        } else {
            this.internal.set(value);
        } 
    }
}

export class IntegerOptionValueAccessor implements IOptionValueAccessor {
    readonly internal: IValueAccessor<number | undefined>;
    readonly options: ReadonlyArray<Option<string>>;
    constructor(
        internal: IValueAccessor<number | undefined>,
        options: ReadonlyArray<Option<number>>
    ) {
        this.internal = internal;
        this.options = [UndefinedOption, ...options.map(x => {
            return {
                value: x.value.toString(10),
                text: x.text
            };
        })];
    }
    public get(): string {
        const value = this.internal.get();
        if (value === undefined || value === null) {
            return UndefinedOption.value;
        }

        return value.toString(10);
    }
    public set(value: string): void {
        if (value === UndefinedOption.value) {
            this.internal.set(undefined);
        } else {
            const n = parseInt(value, 10);
            if (isNaN(n)) {
                throw new Error("Unexpected");
            }
            this.internal.set(n);
        }
    }
}

export class BooleanToOptionValueAccessor implements IOptionValueAccessor {
    readonly internal: IValueAccessor<boolean | undefined>;
    readonly options: ReadonlyArray<Option<string>>
    constructor(
        internal: IValueAccessor<boolean | undefined>,
        options: {
            readonly true: string,
            readonly false: string
        }
    ) {
        this.internal = internal;
        this.options = [
            UndefinedOption, {
                value: "true",
                text: options.true,
            }, {
                value: "false",
                text: options.false,
            }
        ];
    }
    public get(): string {
        const value = this.internal.get();
        if (value === true) {
            return "true";
        }
        else if (value === false) {
            return "false";
        }
        else if (value === undefined || value === null) {
            return UndefinedOption.value;
        }
        throw new Error("Unexpected");
    }
    set(value: string): void {
        if (value === "true") {
            this.internal.set(true);
        }
        else if (value === "false") {
            this.internal.set(false);
        }
        else if (value === UndefinedOption.value) {
            this.internal.set(undefined);
        }
        else {
            throw new Error("Not implemented");
        }
    }
}