/* Generated file */

import { Logement } from './Logement';
import { EtatAvancementProjet } from './EtatAvancementProjet';
import { Reservataire } from './Reservataire';
import { Financement } from './Financement';
import { Label } from './Label';
import { Caracteristique } from './Caracteristique';
import { Nature } from './Nature';
import { TypeLogement } from './TypeLogement';
import { Chauffages } from './Chauffages';

export const initLogement = (bailleurId: number | undefined, programme: string | undefined): Logement => {
	const now = new Date();
	const logement: Logement = {
		bailleur: bailleurId || undefined,
		nomProgramme: programme,
		id: 0,
		creationDate: now,
		lastModificationDate: now,
		complet: false,
		comment: undefined,
		version: 0,
		anneeLivraison: undefined,
		anneeDesignation: undefined,
		logementNeuf: undefined,
		etatAvancementProjet: EtatAvancementProjet.NonPrecise,
		suiviParArchitecte: undefined,
		numBatiment: undefined,
		numLogement: undefined,
		numRpls: undefined,
		reservataire: Reservataire.NonPrecise,
		financement: Financement.NonPrecise,
		adresse: undefined,
		complementAdresse: undefined,
		codePostal: undefined,
		arrondissement: 0,
		preconisationHanditoit: undefined,
		label: Label.NonPrecise,
		labelAutre: undefined,
		caracteristique: Caracteristique.NonPrecise,
		nature: Nature.NonPrecise,
		type: TypeLogement.NonPrecise,
		niveau: undefined,
		surface: undefined,
		chauffage: Chauffages.NonPrecise,
		jardin: false,
		balcon: false,
		terrasse: false,
		loggia: false,
		garage: false,
		parking: false,
		prixParking: undefined,
		loyerHC: undefined,
		charges: undefined,
		chemExtConformeReglementation: undefined,
		chemExtSansMarchesNiRessauts: undefined,
		chemExtRessaut: undefined,
		chemExtPente: undefined,
		chemExtPalier: undefined,
		chemExtRampe: undefined,
		chemExtBordure: undefined,
		chemExtMainCourante: undefined,
		chemExtLargeurSup1m20: undefined,
		chemExtLargeurSup1m60: undefined,
		chemExtSolNonMeuble: undefined,
		chemExtTrou: undefined,
		chemExtObstacle: undefined,
		chemExtInfo: undefined,
		statioDedie: undefined,
		statioProxi: undefined,
		statioSousSol: undefined,
		statioSurface: undefined,
		statioVisiteur: undefined,
		statioSasInt: undefined,
		statioSasSup1m20: undefined,
		statioSasSup1m50: undefined,
		statioSasOculus: undefined,
		statioInfo: undefined,
		communPorteVoie: undefined,
		communPorteResidence: undefined,
		communPorteAuto: undefined,
		communPorteTele: undefined,
		communOuvManu: undefined,
		communDispoHaut: undefined,
		communDispoAngle: undefined,
		communDispoSonore: undefined,
		communDispoCamera: undefined,
		communDispoSansFil: undefined,
		communHallSup1m20: undefined,
		communHallSup1m50: undefined,
		communHallColectif: undefined,
		communHallEclairageAuto: undefined,
		communHallEclairage90: undefined,
		communHallEclairageDiode: undefined,
		communAscenseurBouton: undefined,
		communAscenseurNorme: undefined,
		communAscenseurHori: undefined,
		communInfo: undefined,
		generalLargeurSup90: undefined,
		generalLargeurSup120: undefined,
		generalLargeurPorte90: undefined,
		generalPoignetPorte: undefined,
		entreeLargeur: undefined,
		entreeSerrure: undefined,
		entreePoignee: undefined,
		entreeAuto: undefined,
		entreeEclairage: undefined,
		dispositifHauteur130: undefined,
		dispositifHanteur90: undefined,
		dispositifAngle: undefined,
		dispositifVoletElec: undefined,
		dispositifManoeuvreSecours: undefined,
		salleBainEspace: undefined,
		wcEspace: undefined,
		wcCloison: undefined,
		wcHauteur: undefined,
		wcSuspendu: undefined,
		doucheItalienne: undefined,
		doucheItalienneOk: undefined,
		doucheReceveurEncastre: undefined,
		doucheReceveurEncastreOk: undefined,
		doucheParois: undefined,
		lavaboAdapte: undefined,
		lavaboLibreSous: undefined,
		lavaboProfondeur: undefined,
		lavaboHauteur: undefined,
		lavaboHauteurReglable: undefined,
		lavaboSiphon: undefined,
		chambreDiametre: undefined,
		chambrePassage: undefined,
		cuisinePassage: undefined,
		cuisineEspace: undefined,
		cuisineProfondeur: undefined,
		cuisineSiphon: undefined,
		cuisineCuve: undefined,
		terrasseAcces: undefined,
		terrasseRessaut: undefined,
		logementInfo: undefined,
		communBoiteLettre: undefined,
		entreeEspaceManoeuvre: undefined,
		generalLargeurPorte80: undefined,
		generalEspaceManoeuvre: undefined,
		wcBarreAppui: undefined,
		wcLargeurPorte80: undefined,
		wcLargeurPorte90: undefined,
		wcPoignetPorte: undefined,
		wcEspaceManoeuvre: undefined,
		bainLargeurPorte80: undefined,
		bainLargeurPorte90: undefined,
		bainPoignetPorte: undefined,
		bainEspaceManoeuvre: undefined,
		doucheBarreAppui: undefined,
		doucheSiege: undefined,
		doucheCloisons: undefined,
		chambreLargeurPorte80: undefined,
		chambreLargeurPorte90: undefined,
		chambrePoignetPorte: undefined,
		chambreEspaceManoeuvre: undefined,
		commune: undefined,
		anneeRotation: undefined,
		fiche: undefined,
		medias: [],
	};
	return logement;
};
