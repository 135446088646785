/* Generated file */

export enum Label {
	Aucun = "Aucun",
	Autre = "Autre",
	LogServPMR = "LogServPMR",
	LogServUFR = "LogServUFR",
	NonPrecise = "NonPrecise",
	ProvenceVert = "ProvenceVert",
}
