import { TextField } from "@mui/material";
import { IValueAccessor } from "../utils/ValueAccessor";

export interface OutputTextProps {
    readonly title?: string;
    readonly required: boolean;
    readonly readonly: boolean;
    readonly valueAccessor: IValueAccessor<string | undefined> | undefined;
}

export const OutputText = (props: OutputTextProps) => {
    const { title, valueAccessor } = props;
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        if (valueAccessor && !props.readonly) {
            valueAccessor.set(ev.target.value || undefined)
        }
    };
    return <TextField
        error={valueAccessor && props.required && !valueAccessor.get()}
        size="small"
        value={valueAccessor?.get() ?? ""}
        onChange={handleChange}
        required={props.required}
        label={title}
        sx={{
            mt: (theme) => title ? 0 : theme.spacing(0.5)
        }}
        InputLabelProps={{
            shrink: true
        }}
        InputProps= {{
            readOnly: props.readonly,
            // inputComponent: !valueAccessor ? () => <Skeleton variant="rectangular" readOnly component="input" className="MuiInputBase-input MuiOutlinedInput-input Mui-readOnly MuiInputBase-readOnly"
            // sx={{
            //     width:"100%",
            //     border: 0,
            //     boxSizing: "content-box",
            //     fontSize: "inherit",
            //     height: '1.4375em', // 23px (trouvé dans InputRoot de MUI)
            // }}/> : undefined
        }}
        type="text"
        fullWidth
    />
}

//   const CustomInput = styled(Input)(({ theme }) => ({
//     '& .Mui-disabled': {
//         WebkitTextFillColor: "inherit",
//         color: theme.palette.text.primary
//     },
//   }));
