import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const apiClient = axios.create({
    baseURL: `${window.location.protocol}//${window.location.host}/api`
});

export const apiPost = async <TResult, TData>(url: string, data: TData, config: AxiosRequestConfig<TData> | undefined): Promise<TResult | undefined> => {
    let response: AxiosResponse<TResult, TData> | undefined = undefined;
    await apiClient.post<TResult, AxiosResponse<TResult, TData>, TData>(url, data, config)
    .then(r => {
        response = r;
    })
    .catch(error => {
        if (!axios.isCancel(error)) {
            throw error;
        }
    });
    if (!response) {
        return undefined;
    }
    return (response as AxiosResponse<TResult, TData>).data;
};

export const apiDelete = async <TResult, TData>(url: string, config: AxiosRequestConfig<TData> | undefined): Promise<TResult | undefined> => {
    let response: AxiosResponse<TResult, TData> | undefined = undefined;
    await apiClient.delete<TResult, AxiosResponse<TResult, TData>, TData>(url, config)
    .then(r => {
        response = r;
    })
    .catch(error => {
        if (!axios.isCancel(error)) {
            throw error;
        }
    });
    if (!response) {
        return undefined;
    }
    return (response as AxiosResponse<TResult, TData>).data;
};

export const apiGet = async <TResult, D = any>(url: string, config: AxiosRequestConfig<D> | undefined): Promise<TResult | undefined> => {
    let response: AxiosResponse<TResult, D> | undefined = undefined;
    await apiClient.get<TResult, AxiosResponse<TResult, D>, D>(url, config)
    .then(r => {
        response = r;
    })
    .catch(error => {
        if (!axios.isCancel(error)) {
            throw error;
        }
    });
    if (!response) {
        return undefined;
    }
    return (response as AxiosResponse<TResult, D>).data;
};