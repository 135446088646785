/* Generated file */

export enum Reservataire {
	ActionLogement = "ActionLogement",
	Bailleur = "Bailleur",
	ConseilDepartemental = "ConseilDepartemental",
	EPCI = "EPCI",
	Mairie = "Mairie",
	NonPrecise = "NonPrecise",
	Prefecture = "Prefecture",
}
