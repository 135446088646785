import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

export interface PasswordInputProps {
    readonly password: string | undefined;
    readonly handlePassword: React.ChangeEventHandler<HTMLInputElement>;
    readonly label: string;
    readonly autoComplete?: string;
    readonly error?: boolean;
    readonly helperText?: string;
}

export const PasswordInput = (props: PasswordInputProps) => {
    const { password, handlePassword, label, autoComplete, error, helperText } = props;
    const [showPassword, setShowPassword] = useState(false);
  
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
  
    return (
      <TextField
        type={showPassword ? "text" : "password"}
        label={label}
        value={password}
        onChange={handlePassword}
        required={true}
        InputProps={{
          autoComplete: autoComplete,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={showPassword ? "cacher" : "voir"}
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        // autoComplete={autoComplete}
        error={error}
        helperText={helperText}
      />
    );
  };