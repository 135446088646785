/* Generated file */

export enum Chauffages {
	Autre = "Autre",
	Collectif = "Collectif",
	IndElec = "IndElec",
	IndFuel = "IndFuel",
	IndGaz = "IndGaz",
	NonPrecise = "NonPrecise",
}
