import { Link as RLink, LinkProps, useLocation } from "react-router-dom";
import { Tab, TabProps } from "@mui/material";

export type LinkTabProps = Omit<TabProps, "component"> & Omit<LinkProps, "to"> & { rootPath?: string };

export const LinkTab = (props: LinkTabProps) => {
    const location = useLocation();
    const allProps = {
        ...props,
        to: `${props.rootPath || ""}${props.value}${location.search}`
    };
    delete(allProps.rootPath);
    const tabProps: TabProps = {
        ...allProps,
        component: RLink,
    };
    return <Tab
        {...tabProps}
        sx={theme => ({
            minWidth: theme.spacing(5)
        })}/>;
};