export const isNullOrUndefined = (value: boolean | number | undefined | null): boolean => {
    return value === undefined || value === null;
}

export const isNullOrWithSpace = (text: string | undefined | null): boolean => {
    return text === undefined || text === null || text.trim() === "";
}

export const isNonPrecise = (text: string): boolean => {
    return text === "NonPrecise";
}