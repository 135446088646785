import { Stack, TextareaAutosize } from "@mui/material";
import { LogementPageOutletContext, ViewKind } from "../LogementPage";
import { LogementDivider } from "./LogementTabContent";
import { useOutletContext } from "react-router-dom";

export interface CommentsTabContentProps {
    readonly viewKind: ViewKind;    
}

export const CommentsTabContent = (props: CommentsTabContentProps) => {
    const { logementAccessor } = useOutletContext() as LogementPageOutletContext;
    const chemExtInfo = logementAccessor.chemExtInfo;
    const communInfo = logementAccessor.communInfo;
    const logementInfo = logementAccessor.logementInfo;
    const statioInfo = logementAccessor.statioInfo;
    return (<Stack spacing={1}>
        <LogementDivider text="Cheminements extérieurs"/>
        <TextareaAutosize
            disabled={props.viewKind === ViewKind.Read}
            minRows={5}
            value={chemExtInfo?.get() ?? ""}
            onChange={(ev) => chemExtInfo?.set(ev.target.value)}
            style={{width: "100%", resize: "none"}}
        />
        <LogementDivider text="Parties communes"/>
        <TextareaAutosize
            disabled={props.viewKind === ViewKind.Read}
            minRows={5}
            value={communInfo?.get() ?? ""}
            onChange={(ev) => communInfo?.set(ev.target.value)}
            style={{width: "100%", resize: "none"}}
        />
        <LogementDivider text="Logement"/>
        <TextareaAutosize
            disabled={props.viewKind === ViewKind.Read}
            minRows={5}
            value={logementInfo?.get() ?? ""}
            onChange={(ev) => logementInfo?.set(ev.target.value)}
            style={{width: "100%", resize: "none"}}
        />
        <LogementDivider text="Stationnement"/>
        <TextareaAutosize
            disabled={props.viewKind === ViewKind.Read}
            minRows={5}
            value={statioInfo?.get() ?? ""}
            onChange={(ev) => statioInfo?.set(ev.target.value)}
            style={{width: "100%", resize: "none"}}
        />
    </Stack>);
}