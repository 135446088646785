import { CircularProgress } from "@mui/material";
import { useEffect, useRef } from "react";
import { Await, useLoaderData } from "react-router-dom";
import React from "react";
import { IApiService } from "../services/ApiService";

const logoutUrl = `${window.location.protocol}//${window.location.host}/api/Account/Logout`;

export const LogoutPage = () => {
    const logoutFormRef = useRef<HTMLFormElement>(null);
    const data = useLoaderData() as { api: Promise<IApiService> };
    useEffect(() => {
        if (logoutFormRef.current) {
            logoutFormRef.current.submit();
        }
    }, [logoutFormRef.current])
    return (
        <>
            <CircularProgress />
            <React.Suspense>
                <Await resolve={data.api}>
                    {api => api.antiforgery ? (
                        <form style={{display: "none"}} ref={logoutFormRef} method="post" action={logoutUrl}>
                        <input
                            type="hidden"
                            name={api.antiforgery.formFieldName}
                            value={api.antiforgery.requestToken} />
                        </form>
                    ) : <></>}
                </Await>
            </React.Suspense>
        </>
    );
}