import { Navigate, RouteObject, To, defer, useParams } from "react-router-dom";
import { BailleurItem, IApiService } from "./services/ApiService";
import { HomeLayout } from "./layout/HomeLayout";
import { LoginPage } from "./pages/LoginPage";
import { MainLayout, searchContext } from "./layout/MainLayout";
import { LogoutPage } from "./pages/LogoutPage";
import { ErrorPage } from "./pages/ErrorPage";
import { Box, CircularProgress, InputBase } from "@mui/material";
import { BailleursPage } from "./pages/BailleursPage";
import { ProgrammesPage } from "./pages/ProgrammesPage";
import { LogementsPage } from "./pages/LogementsPage";
import { LogementPage, ViewKind } from "./pages/LogementPage";
import { Logement } from "./dataModel/generated/Logement";
import { SaveTabContent } from "./pages/LogementPageTabs/SaveTabContent";
import { EditTabs, NewTabs } from "./pages/LogementPageTabs/EditTabs";
import { ViewTabs } from "./pages/LogementPageTabs/ViewTabs";
import { HistoriqueTabContent } from "./pages/LogementPageTabs/HistoriqueTabContent";
import { LogementTabContent } from "./pages/LogementPageTabs/LogementTabContent";
import { DiagnosticTabContent } from "./pages/LogementPageTabs/DiagnosticTabContent";
import { DocumentsTabContent } from "./pages/LogementPageTabs/DocumentsTabContent";
import { CommentsTabContent } from "./pages/LogementPageTabs/CommentsTabContent";
import { initLogement } from "./dataModel/generated/initLogement";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { MePage } from "./pages/MePage";
import { useContext } from "react";
import { HomePage } from "./pages/HomePage";

const bailleursLinkPromise = Promise.resolve({
    path: "/bailleurs",
    title: "Bailleurs"
});

export const routeFactory = (apiService: IApiService, offlineReady: boolean, setIsInForm: React.Dispatch<React.SetStateAction<boolean>>): RouteObject[] => {
    const accountRoute: RouteObject = {
        path: "/account",
        errorElement: <ErrorPage />,
        children: !apiService.user ? [{
            element: <HomeLayout/>,
            children: !apiService.antiforgery ? [{
                path: "*",
                element: <CircularProgress/>
            }] : [{
                path:"login",
                element: <LoginPage api={apiService} />
            }, {
                path:"forgot-password",
                element: <ForgotPasswordPage api={apiService} />
            }, {
                path:"reset-password",
                element: <ResetPasswordPage api={apiService} />
            }]
        }] : [{
            element: <MainLayout apiService={apiService}/>,
            loader: ({  }) => { return {}; },
            handle: {
                crumb: (_path: string, _: MainRouteData) => [Promise.resolve({
                    path: "/",
                    title: "Diagnostic Accessibilité"
                })]
            },
            children: [{
                path:"logout",
                element: <LogoutPage />,
                loader: ({  }) => {
                    return defer({
                        api: apiService
                    });
                },
            }, {
                path:"me",
                element: <MePage apiService={apiService}/>,
                loader: ({ }) => {
                    return defer({
                    });
                },
                handle: {
                    crumb: (path: string, _: MainRouteData) => [Promise.resolve({
                        path: path,
                        title: "Mon compte"
                    })]
                },
            }]
        }]
    };

    const mainRoute: RouteObject = (!apiService.antiforgery || !offlineReady) ? {
        path: "/*",
        errorElement: <ErrorPage />,
        element: <HomeLayout/>,
        children: [{
            index: true,
            element: <CircularProgress/>
        }]
    } : !apiService.user ? {
        path: "/*",
        errorElement: <ErrorPage />,
        Component: () => {
            const params = useParams();
            const returnUrl = params["*"];
            const search = returnUrl ? `?returnUrl=/${returnUrl}` : undefined;
            const to: To = {
                pathname: "/account/login",
                search: search,
            };
            return <Navigate to={to} replace/>
        }
    } : {
        path: "/",
        errorElement: <ErrorPage />,
        element: <MainLayout apiService={apiService}/>,
        handle: {
            crumb: (path: string, _: MainRouteData) => [Promise.resolve({
                path: path,
                title: "Diagnostic Accessibilité"
            })]
        },
        children: [{
            index: true,
            element: <HomePage/>,
            loader: () => {
                return defer({
                    user: apiService.user
                });
            },
          },{
            path:"/bailleurs",
            element: <BailleursPage />,
            loader: ({ request }) => {
                return defer({
                    user: apiService.user,
                    bailleurs: apiService.getBailleurs(request.signal)
                });
            },
            handle: {
                crumb: (_path: string, _: MainRouteData) => {
                    if (apiService.user?.bailleurId) {
                        return [];
                    } else {
                        return [bailleursLinkPromise];
                    }
                } 
            },
          }, {
            path:"/:bailleurId",
            loader: ({ params, request }) => {
                if (!params.bailleurId) {
                    throw new Error("Invalid id");
                }
                const bailleurId = parseInt(params.bailleurId, 10);
                if (Number.isNaN(bailleurId)) {
                    throw new Error("Invalid id");
                }
                return defer({
                    bailleur: bailleurId !== 0 ? apiService.getBailleur(bailleurId, request.signal) : Promise.resolve(null)
                });
            },
            handle: {
                crumb: (path: string, data: MainRouteData) => {
                    if (!data.bailleur) {
                        throw new Error("Unexpected");
                    }
                    const bailleurLinkPromise = data.bailleur.then(x => {
                        if (!x) {
                            return {
                                path: path,
                                title: "Hors bailleur"
                            };
                        }
                        return {
                            path: path,
                            title: apiService.user?.bailleurId ? "Programmes" : x.nom
                        };
                    })
                    if (apiService.user?.bailleurId) {
                        return [bailleurLinkPromise];
                    } else {
                        return [bailleursLinkPromise, bailleurLinkPromise];
                    }
                }
            },
            children: [{
                index: true,
                element: <ProgrammesPage />,
                loader: ({ params, request }) => {
                    if (!params.bailleurId) {
                        throw new Error("Invalid id");
                    }
                    const bailleurId = parseInt(params.bailleurId, 10);
                    if (Number.isNaN(bailleurId)) {
                        throw new Error("Invalid id");
                    }
                    return defer({
                        bailleurId: bailleurId,
                        programmes: apiService.getProgrammes(bailleurId, request.signal)
                    });
                },
                handle: {
                    subHeader: (_path: string): React.FunctionComponent => {
                        return () => <SearchBar />;
                    },
                }
            }, {
                path:"logements/:programme?",
                element: <LogementsPage />,
                loader: ({ params, request }) => {
                    if (!params.bailleurId) {
                        throw new Error("Invalid id");
                    }
                    const bailleurId = parseInt(params.bailleurId, 10);
                    if (Number.isNaN(bailleurId)) {
                        throw new Error("Invalid id");
                    }
                    const programme = params.programme;
                    return defer({
                        bailleurId: bailleurId,
                        programme: Promise.resolve(params.programme ?? null),
                        logements: apiService.getLogements(bailleurId, programme, request.signal)
                    });
                },
                handle: {
                    crumb: (path: string, data: MainRouteData) => {
                        if (!data.programme) {
                            throw new Error("Unexpected");
                        }
                        return [data.programme.then(x => {
                            return {
                                path: path,
                                title: x ?? "Hors programme"
                            };
                        })];
                    }
                }
            }]
        }, {
            path:"new",
            element: <LogementPage viewKind={ViewKind.New} apiService={apiService} setIsInForm={setIsInForm} />,
            loader: ({ request }) => {
                const queryStringParams = (new URL(request.url)).searchParams;
                const bailleurIdStr = queryStringParams.get("bailleur") || undefined;
                const bailleurId = apiService.user?.bailleurId || (bailleurIdStr ? parseInt(bailleurIdStr, 10) : undefined);
                if (Number.isNaN(bailleurId)) {
                    throw new Error("Invalid bailleur id");
                }
                const bailleursPromise = apiService.getBailleurs(request.signal);
                const logementPromise = Promise.resolve(initLogement(bailleurId, queryStringParams.get("programme") || undefined));
                const programmePromise = logementPromise.then(x => x.nomProgramme ?? null);
                const logEntriesPromise = Promise.resolve([]);
                const communesPromise = apiService.getCommunes(request.signal);
                return defer({
                    bailleurs: bailleursPromise,
                    programme: programmePromise,
                    logement: logementPromise,
                    logEntries: logEntriesPromise,
                    communes: communesPromise,
                    forceProgramme: queryStringParams.has("programme"),
                    forceBailleur: !!apiService.user?.bailleurId || queryStringParams.has("bailleur")
                });
            },
            handle: {
                subHeader: (path: string): React.FunctionComponent => {
                    return () => <NewTabs path={path}/>
                },
                crumb: (path: string, data: MainRouteData) => {
                    if (!data.programme) {
                        throw new Error("Unexpected");
                    }
                    const programmeLinkPromise = data.programme.then(async x => {
                        const bailleur = await bailleurLinkPromise;
                        return {
                            path: `${bailleur.path}/logements/${x ? encodeURIComponent(x) : ""}`,
                            title: x ?? "Hors programme"
                        };
                    });
                    if (!data.logement) {
                        throw new Error("Unexpected");
                    }
                    const logementLinkPromise = data.logement.then(_x => {
                        return {
                            path: path,
                            title: "Nouveau logement"
                        };
                    });
                    if (!data.bailleurs) {
                        throw new Error("Unexpected");
                    }
                    const bailleurLinkPromise = Promise.all([data.logement, data.bailleurs]).then(p => {
                        const bailleurId = p[0].bailleur;
                        const label = apiService.user?.bailleurId ? "Programmes" : bailleurId ? p[1]?.find(x => x.id === bailleurId)?.nom : undefined;
                        return {
                            path: `/${bailleurId ?? 0}`,
                            title: label ?? "Hors bailleur"
                        };
                    });
                    const r = [];
                    if (data.forceBailleur) {
                        r.push(bailleurLinkPromise);
                    }
                    if (data.forceProgramme) {
                        r.push(programmeLinkPromise);
                    }
                    r.push(logementLinkPromise);
                    return r;
                }
            },
            children: [{
                index: true,
                element: <LogementTabContent viewKind={ViewKind.New} />
            }, {
                path: "diagnostic",
                element: <DiagnosticTabContent viewKind={ViewKind.New} apiService={apiService}/>
            }, {
                path: "comments",
                element: <CommentsTabContent viewKind={ViewKind.New} />
            }, {
                path: "documents",
                element: <DocumentsTabContent viewKind={ViewKind.New} apiService={apiService}/>
            }, {
                path: "save",
                element: <SaveTabContent viewKind={ViewKind.New} apiService={apiService}/>
            }]    
        }, {
            path:"logement/:logementId/view",
            element: <LogementPage viewKind={ViewKind.Read} apiService={apiService} setIsInForm={setIsInForm} />,
            loader: ({ params, request }) => {
                if (!params.logementId) {
                    throw new Error("Invalid id");
                }
                const logementId = parseInt(params.logementId, 10);
                if (Number.isNaN(logementId)) {
                    throw new Error("Invalid id");
                }
                const bailleursPromise = apiService.getBailleurs(request.signal);
                const logementPromise = apiService.getLogement(logementId, request.signal);
                const programmePromise = logementPromise.then(x => x.nomProgramme ?? null);
                const logEntriesPromise = apiService.getLogEntries(logementId, request.signal);
                const communesPromise = apiService.getCommunes(request.signal);
                return defer({
                    bailleurs: bailleursPromise,
                    programme: programmePromise,
                    logement: logementPromise,
                    logEntries: logEntriesPromise,
                    communes: communesPromise
                });
            },
            handle: {
                subHeader: (path: string): React.FunctionComponent => {
                    return () => <ViewTabs path={path}/>
                },
                crumb: (path: string, data: MainRouteData) => {
                    if (!data.programme) {
                        throw new Error("Unexpected");
                    }
                    const programmeLinkPromise = data.programme.then(async x => {
                        const bailleur = await bailleurLinkPromise;
                        return {
                            path: `${bailleur.path}/logements/${x ? encodeURIComponent(x) : ""}`,
                            title: x ?? "Hors programme"
                        };
                    });
                    if (!data.logement) {
                        throw new Error("Unexpected");
                    }
                    const logementLinkPromise = data.logement.then(x => {
                        return {
                            path: path,
                            title: `Logement N°${x.numLogement ?? "???"}`
                        };
                    });
                    if (!data.bailleurs) {
                        throw new Error("Unexpected");
                    }
                    const bailleurLinkPromise = Promise.all([data.logement, data.bailleurs]).then(p => {
                        const bailleurId = p[0].bailleur;
                        const label = apiService.user?.bailleurId ? "Programmes" : bailleurId ? p[1]?.find(x => x.id === bailleurId)?.nom : undefined;
                        return {
                            path: `/${bailleurId ?? 0}`,
                            title: label ?? "Hors bailleur"
                        };
                    });
                    if (apiService.user?.bailleurId) {
                        return [bailleurLinkPromise, programmeLinkPromise, logementLinkPromise];
                    } else {
                        return [bailleursLinkPromise, bailleurLinkPromise, programmeLinkPromise, logementLinkPromise];
                    }
                }
            },
            children: [{
                index: true,
                element: <LogementTabContent viewKind={ViewKind.Read} />
            }, {
                path: "diagnostic",
                element: <DiagnosticTabContent viewKind={ViewKind.Read} apiService={apiService}/>
            }, {
                path: "comments",
                element: <CommentsTabContent viewKind={ViewKind.Read} />
            }, {
                path: "documents",
                element: <DocumentsTabContent viewKind={ViewKind.Read} apiService={apiService}/>
            }, {
                path: "historique",
                element: <HistoriqueTabContent apiService={apiService}/>
            }]
        }, {
            path:"logement/:logementId/edit",
            element: <LogementPage viewKind={ViewKind.Modify} apiService={apiService} setIsInForm={setIsInForm} />,
            loader: ({ params, request }) => {
                if (!params.logementId) {
                    throw new Error("Invalid id");
                }
                const logementId = parseInt(params.logementId, 10);
                if (Number.isNaN(logementId)) {
                    throw new Error("Invalid id");
                }
                const bailleursPromise = apiService.getBailleurs(request.signal);
                const logementPromise = apiService.getLogement(logementId, request.signal);
                const programmePromise = logementPromise.then(x => x.nomProgramme ?? null);
                const logEntriesPromise = apiService.getLogEntries(logementId, request.signal);
                const communesPromise = apiService.getCommunes(request.signal);
                return defer({
                    bailleurs: bailleursPromise,
                    programme: programmePromise,
                    logement: logementPromise,
                    logEntries: logEntriesPromise,
                    communes: communesPromise
                });
            },
            handle: {
                subHeader: (path: string): React.FunctionComponent => {
                    return () => <EditTabs path={path} />
                },
                crumb: (path: string, data: MainRouteData) => {
                    if (!data.programme) {
                        throw new Error("Unexpected");
                    }
                    const programmeLinkPromise = data.programme.then(async x => {
                        const bailleur = await bailleurLinkPromise;
                        return {
                            path: `${bailleur.path}/logements/${x ? encodeURIComponent(x) : ""}`,
                            title: x ?? "Hors programme"
                        };
                    });
                    if (!data.logement) {
                        throw new Error("Unexpected");
                    }
                    const logementLinkPromise = data.logement.then(_x => {
                        return {
                            path: path,
                            title: "Mise à jour"//`Logement N°${x.numLogement ?? "???"}`
                        };
                    });
                    if (!data.bailleurs) {
                        throw new Error("Unexpected");
                    }
                    const bailleurLinkPromise = Promise.all([data.logement, data.bailleurs]).then(p => {
                        const bailleurId = p[0].bailleur;
                        const label = apiService.user?.bailleurId ? "Programmes" : bailleurId ? p[1]?.find(x => x.id === bailleurId)?.nom : undefined;
                        return {
                            path: `/${bailleurId ?? 0}`,
                            title: label ?? "Hors bailleur"
                        };
                    });
                    if (apiService.user?.bailleurId) {
                        return [bailleurLinkPromise, programmeLinkPromise, logementLinkPromise];
                    } else {
                        return [bailleursLinkPromise, bailleurLinkPromise, programmeLinkPromise, logementLinkPromise];
                    }
                }
            },
            children: [{
                index: true,
                element: <LogementTabContent viewKind={ViewKind.Modify} />
            }, {
                path: "diagnostic",
                element: <DiagnosticTabContent viewKind={ViewKind.Modify} apiService={apiService}/>
            }, {
                path: "comments",
                element: <CommentsTabContent viewKind={ViewKind.Modify} />
            }, {
                path: "documents",
                element: <DocumentsTabContent viewKind={ViewKind.Modify} apiService={apiService}/>
            }, {
                path: "save",
                element: <SaveTabContent viewKind={ViewKind.Modify} apiService={apiService}/>
            }]
        }]
    };

    const routes: RouteObject[] = [
        accountRoute,
        mainRoute
    ];

    return routes;
}

export interface MainRouteData {
    readonly bailleur: Promise<BailleurItem | null> | undefined;
    readonly bailleurs: Promise<ReadonlyArray<BailleurItem>> | undefined;
    readonly programme: Promise<string | null> | undefined;
    readonly logement: Promise<Logement> | undefined;
    readonly forceProgramme: boolean | undefined;
    readonly forceBailleur: boolean | undefined;
}

const SearchBar = () => {
    const context = useContext(searchContext);
    return <Box>
        {context && <InputBase
            value={context.search}
            onChange={(ev) => context.setSearch(ev.target.value)}
            placeholder="Rechercher…"
            inputProps={{ 'aria-label': 'rechercher' }}
        />}
    </Box>;
}