import { Alert, Backdrop, Button, CircularProgress, Stack, TextField } from "@mui/material";
import { IApiService } from "../services/ApiService";
import { useState } from "react";
import { Check } from "@mui/icons-material";

export interface ForgotPasswordPageProps {
    readonly api: IApiService;
}

export const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
    const [email, setEmail] = useState<string | undefined>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [done, setDone] = useState(false);
    const [errors, setErrors] = useState<Readonly<{[key: string]: ReadonlyArray<string>}> | undefined>(undefined)
    const formEnabled = email?.trim() && !inProgress;
    const handleClick = async() => {
        if (!formEnabled) {
            return;
        }
        if (!email?.trim()) {
            throw new Error("Unexpected");
        }
        setErrors(undefined);
        setInProgress(true);
        var errors = await props.api.sendResetPasswordEmail(email.trim());
        setInProgress(false);
        if (errors) {
            setErrors(errors);
        } else {
            setDone(true);
        }
    };
    return <>
        {errors && <Alert severity="error">
            {Object.entries(errors).map(x => x[1].map(error => <>
                {error}<br/>
            </>))}
        </Alert>}
        {!done && !inProgress &&
        <Stack 
            component="form"
            spacing={2}
            sx={{mt:5}}>
            <TextField
                onChange={(ev) => setEmail(ev.currentTarget.value)}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse email"
                name="email"
                autoComplete="email"
                autoFocus
            />
            <Button
                onClick={() => handleClick()}
                disabled={!formEnabled}
                type="submit"
                fullWidth
                variant="contained">
                Réinitialiser le mot de passe
            </Button>
        </Stack>}
        <Backdrop open={inProgress}>
            <CircularProgress color="inherit" />
        </Backdrop>
        {done &&
            <Alert icon={<Check fontSize="inherit" />} severity="success">
                Si vous disposez d'un compte, vous recevrez d'ici quelques instants un email pour réinitialiser votre mot de passe.
            </Alert>
        }</>
};