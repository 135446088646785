import { Await, Navigate, useLoaderData } from "react-router-dom";
import { NavigationList, NavigationListSkeleton } from "../components/NavigationList";
import React from "react";
import { BailleurItem } from "../services/ApiService";
import { User } from "../services/User";

export const BailleursPage = () => {
    const data = useLoaderData() as undefined | { bailleurs: Promise<ReadonlyArray<BailleurItem>>, user: User };
    if (data?.user.bailleurId) {
        return <Navigate to={`/${data?.user.bailleurId}`}/>
    }
    return (
        <React.Suspense fallback={<NavigationListSkeleton/>}>
            {data && <Await resolve={data.bailleurs}>
                {(bailleurs: ReadonlyArray<BailleurItem>) => {
                    if (!data.user.bailleurId) {
                        bailleurs = [{ id: 0, nom: "- Hors bailleur -"}, ...bailleurs];
                    } 
                    return (
                        <NavigationList items={bailleurs.map(x => {
                            return {
                                text: x.nom,
                                path: `../${x.id}`,
                            };
                        })} />
                    );
                }}
            </Await>}
        </React.Suspense>
    );
};