import { useLoaderData, Outlet, useNavigate/*, useBlocker, matchPath*/ } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { BailleurItem, CommuneItem, IApiService, LogItem } from "../services/ApiService";
import { Logement } from "../dataModel/generated/Logement";
import { useEffect, useState } from "react";
import { StyledFloatingActionButton } from "../components/StyledFloatingActionButton";
import { Edit } from "@mui/icons-material";
import { LogementOptions } from "../utils/LogementOptions";
import { LogementAccessor } from "../services/LogementAccessor";
//import { useConfirm } from "material-ui-confirm";

export interface LogementPageProps {
    readonly viewKind: ViewKind
    readonly apiService: IApiService
    readonly setIsInForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LogementPage = (props: LogementPageProps) => {
    const navigate = useNavigate();
    const data = useLoaderData() as undefined | {
        programme: Promise<string | null>;
        logement: Promise<Logement>;
        logEntries: Promise<ReadonlyArray<LogItem>>;
        communes: Promise<ReadonlyArray<CommuneItem>>;
        bailleurs: Promise<ReadonlyArray<BailleurItem>>;
        forceProgramme: boolean | undefined;
        forceBailleur: boolean | undefined;
    };
    //const [initialLogement, setInitialLogement] = useState<Logement | undefined>(undefined);
    const [currentLogement, setCurrentLogement] = useState<Logement | undefined>(undefined);
    // const blocker = useBlocker(args => {
    //     const shouldBlock = currentLogement !== initialLogement;
    //     if (props.viewKind === ViewKind.Read || !shouldBlock) {
    //         return false;
    //     }
    //     else if (props.viewKind === ViewKind.Modify) {
    //         const currentRouteMatch = matchPath(`/logement/:id/edit/:tab?`, args.currentLocation.pathname)
    //         if (!currentRouteMatch) {
    //             return false;
    //         }
    //         const nextRouteMatch = matchPath(`/logement/:id/edit/:tab?`, args.nextLocation.pathname)
    //         if (!nextRouteMatch) {
    //             return true;
    //         }
    //         return currentRouteMatch.params.id !== nextRouteMatch.params.id;
    //     }
    //     else if (props.viewKind === ViewKind.New) {
    //         const currentRouteMatch = matchPath(`/new/:tab?`, args.currentLocation.pathname)
    //         if (!currentRouteMatch) {
    //             return false;
    //         }
    //         const nextRouteMatch = matchPath(`/new/:tab?`, args.nextLocation.pathname)
    //         if (!nextRouteMatch) {
    //             return true;
    //         }
    //         return false;
    //     }
    //     throw new Error("Unexpected");
    // });
    // const confirm = useConfirm();
    // useEffect(() => {
    //     console.log("blocker.state", blocker.state)
    //     if (blocker.state === "blocked") {
    //         confirm({
    //             cancellationText: "Non",
    //             confirmationText: "Oui",
    //             title: "Quitter le formulaire ?",
    //             description: "Les données saisies n'ont pas été enregistrées."
    //         }).then(() => {
    //             blocker.proceed();
    //         }).catch(() => {
    //             // canceled
    //         });
    //     }
    // }, [blocker.state]);
    const [logementAccessor, setLogementAccessor] = useState<LogementAccessor>(new LogementAccessor(undefined, (_: Logement) => {}));
    const [logementOptions, setLogementOptions] = useState<LogementOptions>(new LogementOptions(undefined, undefined));
    const logementPromise = data?.logement;
    useEffect(() => {
        logementPromise?.then(logement => {
            setCurrentLogement(logement);
            //setInitialLogement(logement);
       });
    }, [logementPromise]);
    useEffect(() => {
        setLogementAccessor(new LogementAccessor(currentLogement, logement => {
             setCurrentLogement(logement)
        }));
    }, [currentLogement]);
    const communesPromise = data?.communes;
    const bailleursPromise = data?.bailleurs;
    useEffect(() => {
        if (communesPromise && bailleursPromise) {
            Promise.all([communesPromise, bailleursPromise]).then(p => {
                const communes = p[0];
                const bailleurs = p[1];
                setLogementOptions(new LogementOptions(communes, bailleurs))
            });
        }
    }, [communesPromise, bailleursPromise]);
    const handleEditClick = () => {
        navigate("./../edit");
    }
    return (<>
        <Stack sx={{p: 1}} spacing={1}>
            <Box sx={{p: 0}}>
                <Outlet context={{
                    logementAccessor: logementAccessor,
                    logementOptions: logementOptions,
                    logEntriesPromise: data?.logEntries,
                    currentLogement: currentLogement,
                    forceProgramme: data?.forceProgramme,
                    forceBailleur: data?.forceBailleur,
                    setInitialLogement: (_newLogementVersion: Logement) => {
                        // setCurrentLogement(newLogementVersion);
                        // setInitialLogement(newLogementVersion);
                        // navigate(`/logement/${newLogementVersion.id}/view`);
                    }
                }}/>
            </Box>
        </Stack>
        { props.viewKind === ViewKind.Read &&
        <StyledFloatingActionButton color="primary" aria-label="modifier" onClick={handleEditClick}>
            <Edit />
        </StyledFloatingActionButton>}
    </>);
}

export interface LogementPageOutletContext {
    readonly logementAccessor: LogementAccessor;
    readonly logementOptions: LogementOptions;
    readonly logEntriesPromise: Promise<ReadonlyArray<LogItem>> | undefined;
    readonly currentLogement: Logement | undefined;
    readonly forceProgramme: boolean | undefined;
    readonly forceBailleur: boolean | undefined;
    readonly setInitialLogement: React.Dispatch<React.SetStateAction<Logement>>;
}

export enum ViewKind {
    Read,
    Modify,
    New
}
