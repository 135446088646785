import { Card, CardActionArea, CardContent, Skeleton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface NavigationListProps {
    items: ReadonlyArray<ItemProps>;
}

export const NavigationList = (props: NavigationListProps) => {
    const items = props.items;
    return (
        <Stack spacing={1} padding={1}>
            {items.map((x) =>
                <Item key={x.path} path={x.path} text={x.text} />
            )}
        </Stack>
    );
};

export const NavigationListSkeleton = () => {
    const items = [...Array(3).keys()];
    return (
        <Stack spacing={1} padding={1}>
            {items.map(x =>
                <ItemSkeleton key={x} />
            )}
        </Stack>
    );
};

interface ItemProps {
    readonly text: string;
    readonly path: string;
}

const Item = ({ text, path }: ItemProps) => {
    const navigate = useNavigate();
    return (
        <Card onClick={() => navigate(path)}>
            <CardActionArea>
                <CardContent>
                    <Typography variant="body1">
                        {text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

const ItemSkeleton = () => {
    return (
        <Skeleton component="div" variant="rounded" sx={{maxWidth: "none"}}>
            <Card>
                <CardActionArea>
                    <CardContent>
                        <Typography variant="body1">
                            Hidden
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Skeleton>
    );
}