import { Await, useLoaderData, useNavigate } from "react-router-dom";
import React from "react";
import { StyledFloatingActionButton } from "../components/StyledFloatingActionButton";
import { Add } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Skeleton, Typography, Stack, Grid, Chip } from "@mui/material";
import { Logement } from "../dataModel/generated/Logement";
import { OutputText } from "../components/OutputText";
import { IntegerOptionValueAccessor } from "../utils/ValueAccessor";
import { LogementOptions } from "../utils/LogementOptions";
import { OutputSelect } from "../components/OutputSelect";
import { LogementAccessor } from "../services/LogementAccessor";
import { NavigationListSkeleton } from "../components/NavigationList";

export const LogementsPage = () => {
    const data = useLoaderData() as undefined | {
        bailleurId: number | undefined,
        programme: Promise<string | undefined>,
        logements: Promise<ReadonlyArray<Logement>>
    };
    const navigate = useNavigate();
    const handleAddClick = (programme: string | undefined) => {
        if (!data) {
            throw new Error("Unexpected");
        }
        const url = `/new?bailleur=${data.bailleurId || ""}&programme=${programme || ""}`;
        navigate(url);
    };
    const c = (v: [string | undefined, ReadonlyArray<Logement>]) => {
        const programme = v[0];
        const logements = v[1];
        return <>
           <Stack spacing={1} padding={1}>
                {logements.map((x) =>
                    <Item key={x.id} logementAccessor={new LogementAccessor(x, (_: Logement) => {})} />
                )}
            </Stack>
            <StyledFloatingActionButton color="primary" aria-label="ajouter" onClick={() => handleAddClick(programme)}>
                <Add />
            </StyledFloatingActionButton>
        </>;
    };
    return (
        <React.Suspense fallback={<NavigationListSkeleton/>}>
            {data && <Await resolve={Promise.all([data.programme, data.logements])}>
                {c}
            </Await>}
        </React.Suspense>
    );
};

// export const ListSkeleton = () => {
//     const items = [...Array(3).keys()];
//     return (
//         <Stack spacing={1} padding={1}>
//             {items.map(x =>
//                 <Item key={x} logementAccessor={new LogementAccessor(undefined, (_: Logement) => {})} />
//             )}
//         </Stack>
//     );
// };

interface ItemProps {
    logementAccessor: LogementAccessor
}

const Item = ({ logementAccessor }: ItemProps) => {
    const navigate = useNavigate();
    const logementOptions = new LogementOptions(undefined, undefined);
    const handleClick = () => {
        const idAccessor = logementAccessor.id;
        if (idAccessor) {
            navigate(`../../logement/${idAccessor.get()}/view`);
        }
    };
    return (
        <Card onClick={handleClick}>
            <CardActionArea>
                <CardContent sx={{padding: 1}}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <OutputText
                                title="Bâtiment"
                                required={false}
                                readonly={true}
                                valueAccessor={logementAccessor.numBatiment}/>
                        </Grid>
                        <Grid item xs={4}>
                            <OutputText
                                title="Type"
                                required={false}
                                readonly={true}
                                valueAccessor={logementAccessor.type}/>
                        </Grid>
                        <Grid item xs={4}>
                            <OutputSelect
                                title="Niveau"
                                required={false}
                                readonly={true}
                                valueAccessor={logementAccessor.niveau
                                    ? new IntegerOptionValueAccessor(
                                        logementAccessor.niveau,
                                        logementOptions.niveau)
                                    : undefined}
                                />
                        </Grid>
                        <Grid item xs={4}>
                            <OutputText
                                title="Numéro"
                                required={false}
                                readonly={true}
                                valueAccessor={logementAccessor.numLogement}/>
                        </Grid>
                        <Grid item xs={4}>
                            <OutputText
                                title="Numéro RPLS"
                                required={false}
                                readonly={true}
                                valueAccessor={logementAccessor.numRpls}/>
                        </Grid>
                        <Grid item xs={4}>
                            {logementAccessor.complet && <Chip label={logementAccessor.complet.get() ? "Complet" : "Incomplet"} color={logementAccessor.complet.get() ? "success" : "error"} />}
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Typography variant="body1" component="em">
                                Dernière modification:
                                {logementAccessor.lastModificationDate && logementAccessor.lastModificationDate.get().toLocaleDateString()}
                                {!logementAccessor.lastModificationDate && <Skeleton variant="text" />}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}