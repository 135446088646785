import { Box, Container, Typography } from "@mui/material";
import ag2rLogo from '../assets/logo-ag2r.jpg';
import handitoitLogo from '../assets/logo-handitoit.png';
import { Outlet } from "react-router-dom";

export const HomeLayout = () => {
    return (
    <Container sx={{p:2, textAlign: "center"}} maxWidth="xs">
        <Box>
            <img src={handitoitLogo} width="120px" alt="Handitoit"/>
        </Box>
        <Typography variant="body2" textAlign="center" sx={{margin: 2}}>
            en partenariat avec
        </Typography>
        <Box>
            <img src={ag2rLogo} width="220px" alt="AG2R La Mondiale"/>
        </Box>
        <Outlet />
    </Container>);
}