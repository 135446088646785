/* Generated file */

import { Logement } from './Logement';
import { isNullOrWithSpace } from '../utils';
import { isNullOrUndefined } from '../utils';
import { isNonPrecise } from '../utils';
import { isDiagnosticReponseNullOrUndefined } from '../../utils/isDiagnosticReponseNullOrUndefined';

export const normalizeLogement = (logement: Logement): Logement => {
	const normalized = { ...logement };
	normalized.nomProgramme = normalized.nomProgramme?.toUpperCase();
	normalized.comment = isNullOrWithSpace(normalized.comment) ? undefined : normalized.comment;
	normalized.nomProgramme = isNullOrWithSpace(normalized.nomProgramme) ? undefined : normalized.nomProgramme;
	normalized.numBatiment = isNullOrWithSpace(normalized.numBatiment) ? undefined : normalized.numBatiment;
	normalized.numLogement = isNullOrWithSpace(normalized.numLogement) ? undefined : normalized.numLogement;
	normalized.numRpls = isNullOrWithSpace(normalized.numRpls) ? undefined : normalized.numRpls;
	normalized.adresse = isNullOrWithSpace(normalized.adresse) ? undefined : normalized.adresse;
	normalized.complementAdresse = isNullOrWithSpace(normalized.complementAdresse) ? undefined : normalized.complementAdresse;
	normalized.codePostal = isNullOrWithSpace(normalized.codePostal) ? undefined : normalized.codePostal;
	normalized.labelAutre = isNullOrWithSpace(normalized.labelAutre) ? undefined : normalized.labelAutre;
	if (!(normalized.label === "Autre"))
	{
		normalized.labelAutre = undefined;
	}
	normalized.surface = isNullOrWithSpace(normalized.surface) ? undefined : normalized.surface;
	normalized.prixParking = isNullOrWithSpace(normalized.prixParking) ? undefined : normalized.prixParking;
	if (!(normalized.garage || normalized.parking))
	{
		normalized.prixParking = undefined;
	}
	normalized.chemExtInfo = isNullOrWithSpace(normalized.chemExtInfo) ? undefined : normalized.chemExtInfo;
	normalized.statioInfo = isNullOrWithSpace(normalized.statioInfo) ? undefined : normalized.statioInfo;
	normalized.communInfo = isNullOrWithSpace(normalized.communInfo) ? undefined : normalized.communInfo;
	normalized.logementInfo = isNullOrWithSpace(normalized.logementInfo) ? undefined : normalized.logementInfo;
	if (!(normalized.chemExtConformeReglementation === "Non"))
	{
		normalized.chemExtSansMarchesNiRessauts = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non"))
	{
		normalized.chemExtRessaut = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non"))
	{
		normalized.chemExtPente = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non"))
	{
		normalized.chemExtPalier = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non"))
	{
		normalized.chemExtBordure = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non"))
	{
		normalized.chemExtRampe = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSansMarchesNiRessauts === "Non"))
	{
		normalized.chemExtMainCourante = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non"))
	{
		normalized.chemExtLargeurSup1m20 = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non"))
	{
		normalized.chemExtLargeurSup1m60 = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non"))
	{
		normalized.chemExtSolNonMeuble = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSolNonMeuble === "Non"))
	{
		normalized.chemExtTrou = undefined;
	}
	if (!(normalized.chemExtConformeReglementation === "Non" && normalized.chemExtSolNonMeuble === "Non"))
	{
		normalized.chemExtObstacle = undefined;
	}
	if (!(normalized.doucheReceveurEncastre !== "Oui"))
	{
		normalized.doucheItalienne = undefined;
	}
	if (!(normalized.doucheItalienne === "Oui"))
	{
		normalized.doucheItalienneOk = undefined;
	}
	if (!(normalized.doucheItalienne !== "Oui"))
	{
		normalized.doucheReceveurEncastre = undefined;
	}
	if (!(normalized.doucheReceveurEncastre === "Oui"))
	{
		normalized.doucheReceveurEncastreOk = undefined;
	}
	if (!(normalized.lavaboAdapte === "Oui"))
	{
		normalized.lavaboLibreSous = undefined;
	}
	if (!(normalized.lavaboAdapte === "Oui"))
	{
		normalized.lavaboProfondeur = undefined;
	}
	if (!(normalized.lavaboAdapte === "Oui"))
	{
		normalized.lavaboHauteur = undefined;
	}
	if (!(normalized.lavaboAdapte === "Oui"))
	{
		normalized.lavaboHauteurReglable = undefined;
	}
	if (!(normalized.lavaboAdapte === "Oui"))
	{
		normalized.lavaboSiphon = undefined;
	}
	normalized.complet = computeComplet(normalized);
	return normalized;
};

const computeComplet = (logement: Logement) => {
	if (isNullOrUndefined(logement.logementNeuf))
	{
		return false;
	}
	if (isNonPrecise(logement.etatAvancementProjet))
	{
		return false;
	}
	if (isNullOrUndefined(logement.suiviParArchitecte))
	{
		return false;
	}
	if (isNullOrWithSpace(logement.numBatiment))
	{
		return false;
	}
	if (isNullOrWithSpace(logement.numLogement))
	{
		return false;
	}
	if (isNullOrWithSpace(logement.adresse))
	{
		return false;
	}
	if (isNullOrWithSpace(logement.codePostal))
	{
		return false;
	}
	if (isNullOrUndefined(logement.preconisationHanditoit))
	{
		return false;
	}
	if (logement.label === "Autre" && isNullOrWithSpace(logement.labelAutre))
	{
		return false;
	}
	if (isNonPrecise(logement.caracteristique))
	{
		return false;
	}
	if (isNonPrecise(logement.nature))
	{
		return false;
	}
	if (isNonPrecise(logement.type))
	{
		return false;
	}
	if (isNullOrUndefined(logement.niveau))
	{
		return false;
	}
	if (isNullOrWithSpace(logement.surface))
	{
		return false;
	}
	if (isNonPrecise(logement.chauffage))
	{
		return false;
	}
	if (logement.garage || logement.parking && isNullOrWithSpace(logement.prixParking))
	{
		return false;
	}
	if (isNullOrWithSpace(logement.commune))
	{
		return false;
	}
	return computeDiagnosticComplet(logement);
};

const computeDiagnosticComplet = (logement: Logement): boolean => {
	if (isDiagnosticReponseNullOrUndefined(logement.chemExtConformeReglementation)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtSansMarchesNiRessauts)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && logement.chemExtSansMarchesNiRessauts === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtRessaut)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && logement.chemExtSansMarchesNiRessauts === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtPente)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && logement.chemExtSansMarchesNiRessauts === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtPalier)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && logement.chemExtSansMarchesNiRessauts === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtBordure)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && logement.chemExtSansMarchesNiRessauts === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtRampe)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && logement.chemExtSansMarchesNiRessauts === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtMainCourante)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtLargeurSup1m20)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtLargeurSup1m60)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtSolNonMeuble)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && logement.chemExtSolNonMeuble === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtTrou)) {
		return false;
	}
	if (logement.chemExtConformeReglementation === "Non" && logement.chemExtSolNonMeuble === "Non" && isDiagnosticReponseNullOrUndefined(logement.chemExtObstacle)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communPorteVoie)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communPorteResidence)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communPorteAuto)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communPorteTele)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communOuvManu)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communDispoHaut)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communDispoAngle)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communDispoSonore)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communDispoCamera)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communDispoSansFil)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communHallSup1m20)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communHallSup1m50)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communHallColectif)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communHallEclairageAuto)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communHallEclairage90)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communHallEclairageDiode)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communAscenseurBouton)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communAscenseurNorme)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communAscenseurHori)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.communBoiteLettre)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.entreeLargeur)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.entreeSerrure)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.entreePoignee)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.entreeEspaceManoeuvre)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.entreeAuto)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.entreeEclairage)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.dispositifHauteur130)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.dispositifHanteur90)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.dispositifAngle)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.dispositifVoletElec)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.dispositifManoeuvreSecours)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.generalLargeurSup90)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.generalLargeurSup120)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.generalLargeurPorte80)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.generalLargeurPorte90)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.generalPoignetPorte)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.generalEspaceManoeuvre)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.cuisinePassage)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.cuisineEspace)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.cuisineProfondeur)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.cuisineSiphon)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.cuisineCuve)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.terrasseAcces)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.terrasseRessaut)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.chambreDiametre)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.chambrePassage)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.chambreLargeurPorte80)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.chambreLargeurPorte90)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.chambrePoignetPorte)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.chambreEspaceManoeuvre)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.salleBainEspace)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.bainLargeurPorte80)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.bainLargeurPorte90)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.bainPoignetPorte)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.bainEspaceManoeuvre)) {
		return false;
	}
	if (logement.doucheReceveurEncastre !== "Oui" && isDiagnosticReponseNullOrUndefined(logement.doucheItalienne)) {
		return false;
	}
	if (logement.doucheItalienne === "Oui" && isDiagnosticReponseNullOrUndefined(logement.doucheItalienneOk)) {
		return false;
	}
	if (logement.doucheItalienne !== "Oui" && isDiagnosticReponseNullOrUndefined(logement.doucheReceveurEncastre)) {
		return false;
	}
	if (logement.doucheReceveurEncastre === "Oui" && isDiagnosticReponseNullOrUndefined(logement.doucheReceveurEncastreOk)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.doucheParois)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.doucheBarreAppui)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.doucheSiege)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.doucheCloisons)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.lavaboAdapte)) {
		return false;
	}
	if (logement.lavaboAdapte === "Oui" && isDiagnosticReponseNullOrUndefined(logement.lavaboLibreSous)) {
		return false;
	}
	if (logement.lavaboAdapte === "Oui" && isDiagnosticReponseNullOrUndefined(logement.lavaboProfondeur)) {
		return false;
	}
	if (logement.lavaboAdapte === "Oui" && isDiagnosticReponseNullOrUndefined(logement.lavaboHauteur)) {
		return false;
	}
	if (logement.lavaboAdapte === "Oui" && isDiagnosticReponseNullOrUndefined(logement.lavaboHauteurReglable)) {
		return false;
	}
	if (logement.lavaboAdapte === "Oui" && isDiagnosticReponseNullOrUndefined(logement.lavaboSiphon)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcEspace)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcCloison)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcHauteur)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcSuspendu)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcBarreAppui)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcLargeurPorte80)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcLargeurPorte90)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcPoignetPorte)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.wcEspaceManoeuvre)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioDedie)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioProxi)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioSousSol)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioSurface)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioVisiteur)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioSasInt)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioSasSup1m20)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioSasSup1m50)) {
		return false;
	}
	if (isDiagnosticReponseNullOrUndefined(logement.statioSasOculus)) {
		return false;
	}
	return true;
};
